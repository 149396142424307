import { useTranslation } from "react-i18next";

import LocationSvg from "../../assets/Location.svg";
import { NavLink } from "react-router-dom";

function HeroSection() {
  const { t } = useTranslation("aboutUs");

  return (
    <>
      <div className="about-section-bg relative flex z-[99] flex-col bg-cover bg-left w-full sm:bg-mobile">
        <div className="container mx-auto lg:px-[20px] px-[16px] h-full flex flex-col leading-[29.05px] 2xl:pt-[170px] lg:pb-[196px] lg:pt-[100px] pt-[75px] pb-[144px]">
          <div className="mt-auto font-semibold text-[24px] ">
            {/* <p className="font-bold">{t("heroSection.subtitle")}</p> */}
          </div>
          <div className="lg:text-[55px] text-[48px] lg:leading-[77px] leading-[67px] font-medium text-black mt-6 mb-8 ">
            <h1 className="text-[#3E6BE4]">{t("heroSection.title")}</h1>
            <h1>{t("heroSection.titlePart2")}</h1>
          </div>

          <div className="mb-auto text-[16px] font-normal ">
            <NavLink to="/contact#map" className="flex">
              <img src={LocationSvg} alt="Location" className="mr-4" />
              {t("heroSection.location")}
            </NavLink>
          </div>
        </div>
      </div>
      <div className="container relative mx-auto lg:px-[20px] px-[16px] flex lg:pt-[100px] lg:pb-[60px] pt-[64px] pb-[48px] text-[#788497] text-center text-[12px] leading-[19px] font-medium">
        <p>{t("heroSection.text")}</p>
      </div>
    </>
  );
}

export default HeroSection;
