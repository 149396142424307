import { useTranslation } from "react-i18next";
import tfLogo from "../../assets/bgLogoTF.svg";
import tfLogoMobile from "../../assets/bgLogoTFMobile.svg";

import TitleSection from "../TitleSection/TitleSection";

function WhoIsTF() {
  const { t } = useTranslation("home");

  return (
    <div className="w-full flex flex-col gap-[48px] lg:pt-[76px] pt-[60px] lg:pb-[100px] pb-[40px] relative z-[999]">
      <div className="z-20">
      <TitleSection
        title={t("about.title")}
        subtitle={t("about.subtitle")}
        subtitleColor="text-[#788497]"
        showTitleSecond
        
      />
      </div>
      <div className="flex flex-col relative lg:gap-[40px] gap-[32px] text-black text-[14px] leading-[22px] lg:leading-[25px] lg:text-[16px] lg:text-left text-center">
        <p className="relative z-20">{t("about.textOne")}</p>
        <img
            src={tfLogoMobile}
            alt="tf_logo"
            className="absolute top-[54px] left-[50%] transform translate-x-[-50%] z-10 md:hidden block"
          />
      </div>
        <img
            src={tfLogo}
            alt="tf_logo"
            className="absolute lg:top-[76px] top-[50%] left-[50%] lg:translate-y-0 translate-y-[-50%] transform translate-x-[-50%] z-10 md:block hidden"
          />
    </div>
  );
}

export default WhoIsTF;
