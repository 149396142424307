import { useTranslation } from "react-i18next";

import MailSVG from "../../assets/Mail.svg";
import LinkedinSVG from "../../assets/linkedinIcon.svg";
import DirectorImage from "../../assets/Director.png";

function DirectorSection() {
  const { t } = useTranslation("aboutUs");
  return (
    <div className="container overflow-x-hidden lg:pt-[100px] lg:pb-[50px] pt-[72px] pb-[64px] mx-auto lg:px-[20px] px-[16px] flex xl:flex-row flex-col justify-between items-center gap-[64px]">
      <div className="flex flex-col justify-center max-w-[797px] lg:pb-0 gap-[32px]">
        <div className="lg:font-bold font-semibold lg:text-[18px] text-[16px] text-[#3E6BE4] ">
          <p>{t("directorSection.subtitle")}</p>
        </div>
        <div className="flex flex-col gap-[64px]">
          <div className="font-medium lg:text-[45px] text-[24px]">
            <h1>{t("directorSection.title")}</h1>
          </div>
          <div className="text-[12px] leading-[19px]">
            <p className="">{t("directorSection.text")}</p>
            <br />
            <p>{t("directorSection.textPart2")}</p>
            <br />
            <p>{t("directorSection.textPart3")}</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:px-0 px-[16px] lg:pb-0 pb-[50px] lg:mr-0 lg:ml-0 items-center gap-6">
        <div className="w-[275px] h-[316px] relative bg-[#C8E64C] skew-x-[-3deg]">
          <img
            src={DirectorImage}
            alt="Director"
            className="w-[295px] max-w-[295px]  skew-x-[3deg] absolute top-[50%] left-[-10px] translate-y-[-50%] mx-auto"
          />
        </div>
        <div className="flex text-[18px] text-[#788497] leading-[28.8px] text-center">
          <p className="mx-auto">{t("directorSection.director")}</p>
        </div>
        <div className="flex items-center gap-[16px]">
          <a
            href="https://www.linkedin.com/in/admir-ademovic-8102079/"
            target="_blank"
            rel="noreferrer"
            className="w-[35px]"
          >
            <img src={LinkedinSVG} alt="Linkedin" />
          </a>
          <a
            href="mailto:admir.ademovic@tfcapital.me"
            className="w-[35px] h-[35px]"
          >
            <img src={MailSVG} alt="Mail" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default DirectorSection;
