import carbonSendIcon from "../../assets/carbon_send.svg";
import { useTranslation } from "react-i18next";
import { signup, getContacts } from "../../api/repositories/newsletterSignup";
import { useMutation } from "@tanstack/react-query";
import { Formik, Field, Form } from "formik";
import { useEffect, useState } from "react";

function Newsletter() {
	const { t } = useTranslation("usaBerza");
	const [email, setEmail] = useState("");
	const [error, setError] = useState(false);
	const [emailExsists, setEmailExsists] = useState(false);
	const [contacts, setContacts] = useState([]);

	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	const initialValues = {
		email: ""
	};

	const { mutate, status } = useMutation({
		mutationFn: signup,
		onSuccess: () => {
			console.log("Uspjesno poslato");
		},
		onError: (error) => {
			console.log(error);
		},
	});

	const checkIfEmailExsists = (emailAddress) => {
		const emailExists = contacts.includes(emailAddress);
		return emailExists;
	};

	const handleSubmit = () => {
		if (!email || !emailRegex.test(email)) {
			setError(true);
			return false;
		} else if (checkIfEmailExsists(email)) {
			setEmailExsists(true);
			throw new Error('Email already exsists');
		} else {
			setError(false);
			setEmailExsists(false);
			const payload = {
				email: email
			};
			console.log(payload);
			mutate(payload);
			//reset form
			setEmail("");
		}
	};

	useEffect(() => {
		// Fetch contacts data asynchronously
		const fetchContacts = async () => {
			try {
				// Assuming getContacts() returns a Promise that resolves with an array of contacts
				const response = await getContacts();
				// Check if response is an array before setting the state
				if (Array.isArray(response.data.email_addresses)) {
					setContacts(response.data.email_addresses);
				} else {
					console.error('Response is not an array:', response.data.email_addresses);
				}
			} catch (error) {
				console.error('Error fetching contacts:', error);
			}
		};
		// Call the fetchContacts function
		fetchContacts();
	}, []);

	return (
		<div className="w-full relative newsletter-bg">
			<div className="container xl:pt-[59px] xl:pb-[101px] py-[48px] mx-auto px-[16px] lg:px-[20px] flex xl:flex-row flex-col items-start justify-between xl:gap-[40px]">
				<div className="xl:pb-0 2xl:max-w-[563px] xl:max-w-[520px] flex flex-col items-start justify-between gap-[24px]">
					<span className="font-bold text-[#C8E64C] uppercase text-[12px] leading-normal">
						{t("sectionFour.subtitle-1")}
					</span>
					<h4 className="lg:pb-[12px] lg:text-[30px] lg:leading-[42px] text-[24px] leading-[33px] font-semibold text-white">
						{t("sectionFour.title-1")}{" "}
					</h4>
					<p className="text-[12px] leading-[19px] text-white">
						{t("sectionFour.text")}
					</p>
					<button type="submit" className="button-small button-primary">
						{t("sectionFour.button")}
					</button>
				</div>
				<div className="xl:flex hidden xl:pt-0 2xl:max-w-[563px] xl:max-w-[520px] flex-col justify-between gap-[24px]">
					<span className="font-bold text-[#3E6BE4] uppercase text-[12px] leading-normal">
						{t("sectionFour.subtitle-2")}
					</span>
					<h4 className="lg:pb-[32px] text-[30px] leading-[42px] font-semibold text-black">
						{t("sectionFour.title-2")}
					</h4>
					<div className="flex flex-col gap-[12px]">
						<Formik
							initialValues={initialValues}
							onSubmit={handleSubmit}
						>
							<Form>
								<div className="flex items-center gap-[12px]">
								</div>
								<div className="flex items-center">
									<div className="relative w-[466px]">
										<label className="absolute top-[-20px] text-[12px]">Email</label>
										<input
											name="email"
											className="h-[38px] focus:outline-none px-[16px] py-[15px] w-full text-[#788497] text-[10px] leading-[19px]"
											placeholder={t("sectionFour.label")}
											onChange={(e) => setEmail(e.target.value)}
										/>
									</div>
									<button className="-ml-5 cursor-pointer h-[38px] flex items-center justify-center bg-[#3E68E4] w-[82px] transform -skew-x-12 ">
										<img
											className="transform skew-x-12"
											src={carbonSendIcon}
											alt="send-icon"
										/>
									</button>
								</div>
								{error && (
									<span className="text-[#FD5869] text-[12px]">{t(`sectionFour.emailNotEntered`)}</span>
								)}

								{emailExsists && (
									<span className="text-[#FD5869] text-[12px]">{t(`sectionFour.emailExsists`)}</span>
								)}
							</Form>
						</Formik>
					</div>
				</div>
				<p className="text-[12px] leading-[19px] text-black text-right absolute bottom-5 right-5">
					{t("sectionFour.checkBoxText")}
				</p>
			</div>
			<div className="xl:hidden block w-full relative bg-[#C8E64C]">
				<div className="container xl:py-[120px] py-[48px] mx-auto px-[16px] lg:px-[20px] flex xl:flex-row flex-col xl:gap-[40px]">
					<div className="flex xl:pt-0 2xl:max-w-[563px] xl:max-w-[520px] flex-col justify-between gap-[24px]">
						<span className="font-bold text-[#3E6BE4] uppercase text-[12px] leading-normal">
							{t("sectionFour.subtitle-2")}
						</span>
						<h4 className="lg:pb-[22px] text-[24px] leading-[33px] font-semibold text-black">
							{t("sectionFour.title-2")}
						</h4>
						<div className="flex flex-col gap-[12px]">
							<div className="flex items-center">
								<Formik
									initialValues={initialValues}
									onSubmit={handleSubmit}
								>
									<Form className="flex w-full">
										<div className="relative w-full">
											<input
												type="text"
												className="h-[38px] focus:outline-none px-[16px] py-[15px] w-full text-[#788497] text-[10px] leading-[19px]"
												placeholder={t("sectionFour.label")}
											/>
										</div>
										<button className="-ml-5 cursor-pointer h-[38px] flex items-center justify-center bg-[#3E68E4] w-[82px] transform -skew-x-12 ">
											<img
												className="transform skew-x-12"
												src={carbonSendIcon}
												alt="send-icon"
											/>
										</button>
									</Form>
								</Formik>
							</div>
						</div>
						<p className="text-[10px] w-[200px] leading-[19px] text-black text-right absolute bottom-1 right-5">
							{t("sectionFour.checkBoxText")}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Newsletter;
