import link from "../../assets/linkArr.svg";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { format, parseISO } from "date-fns";
import NewsHeroCard from "../NewsHeroCard/NewsHeroCard";

function NewsReUse({
  news,
  title,
  navLink,
  navLinkTitle,
  hideNavLink = false,
}) {
  const locale = useTranslation("home").i18n.language;

  function transformSentence(sentence) {
    let lowercaseSentence = sentence.toLowerCase();
    let words = lowercaseSentence.split(' ');
    let capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    let transformedSentence = capitalizedWords.join(' ');

    return transformedSentence;
  }

  const getNewsLabel = (label) => {
    switch (label) {
      case "Finansijska Terminologija":
        return locale === "me" ? "Finansijska Terminologija" : "Financial Terminology";
      case "Finansijsko Osvjescivanje":
        return locale === "me" ? "Finansijsko Osvjescivanje" : "Financial Awerness";
      case "Ostalo":
        return locale === "me" ? "Ostale vijesti" : "Other news";
      default:
        return "Vesti";
    }
  };

  return (
    <div className="w-full">
      {/* Container */}
      <div className="container mx-auto  lg:px-[20px] px-[16px]">
        {/* Current Affairs section */}
        <div className="py-[52px]">
          {/* 1/3 section */}
          <div className="flex justify-between mb-[48px]">
            <h1 className="text-[18px] leading-[30px] text-[#000] font-medium ">
              {title}
            </h1>
            {!hideNavLink && (
              <div className="lg:flex hidden gap-[12px] cursor-pointer">
                <NavLink
                  to={navLink}
                  className="text-[14px] leading-[30px] text-[#3E6BE4] font-semibold cursor-pointer"
                >
                  {navLinkTitle}
                </NavLink>
                <img
                  src={link}
                  alt="link-arrow"
                  className="cursor-pointer w-[9.75px]"
                ></img>
              </div>
            )}
          </div>
          {/* 2/3 section */}
          <div className="grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-[22px] xl:gap-[13px]">
            {news.map((newsItem, index) => {
              const subcategory = transformSentence(newsItem.subcategory);
              const tags = newsItem?.tags.split(",");
              return (
                <div key={index}>
                  <NewsHeroCard
                    title={newsItem.title[locale]}
                    short_description={newsItem.short_description[locale]}
                    category={getNewsLabel(subcategory)}
                    tags={tags}
                    id={newsItem.id}
                    coverImg={newsItem.full_photo_path || ""}
                  />
                </div>
              );
            })}
          </div>
          {!hideNavLink && (
            <div className="mt-[36px] items-center justify-center flex lg:hidden gap-[12px] cursor-pointer">
              <NavLink
                to={navLink}
                className="text-[14px] leading-[30px] text-[#3E6BE4] font-semibold cursor-pointer"
              >
                {navLinkTitle}
              </NavLink>
              <img
                src={link}
                alt="link-arrow"
                className="cursor-pointer w-[9.75px]"
              ></img>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default NewsReUse;
