import laptopImg from "../../assets/laptop.png";
import metaTraderIcon from "../../assets/metatrader-icon.svg";
import windowsIcon from "../../assets/windows.svg";
import macOsIcon from "../../assets/macOsIcon.png";
import linuxIcon from "../../assets/linux.svg";
import StocksSLider from "../StocksSlider/StocksSlider";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ForexCFD() {
  const navigate = useNavigate();
  const { t } = useTranslation("home");
  return (
    <div className="forex-section-bg w-full pb-[30px] lg:pt-[10px] py-[50px]">
      <div className="container lg:px-[20px] px-[16px] mx-auto flex flex-col gap-[30px]">
        <div className="flex justify-between  lg:flex-row flex-col items-center gap-[24px]">
          <div className="flex flex-col gap-[36px] max-w-[535px]">
            <div className="flex flex-col gap-[32px]">
              <div>
                <span className="lg:font-medium font-bold 2xl:text-[16px] 2xl:leading-[29px] lg:text-[16px] lg:leading-[22px] text-[16px] leading-[22px] text-[#3E6BE4]">
                  {t("forexCFD.subtitle")}
                </span>
              </div>
              <div>
                <h4 className="text-[#293B84] 2xl:text-[30px] leading-[35px] text-[32px] font-medium">
                  {t("forexCFD.titleOne")}
                </h4>
                <h4 className="text-[#293B84] 2xl:text-[30px] leading-[35px] text-[32px]font-medium">
                  {t("forexCFD.titleTwo")}
                </h4>
                <h4 className="text-[#293B84] 2xl:text-[30px] leading-[35px] text-[32px] font-bold">
                  {t("forexCFD.titleThree")}
                </h4>
              </div>
            </div>
            <div className="flex flex-col gap-[32px] text-black text-[14px] leading-[19.5px]">
              <p dangerouslySetInnerHTML={{ __html: t("forexCFD.text") }}></p>
              <div className="flex items-center flex-row gap-[8px]">
                <a
                  href="https://my.tfcapital.me/register"
                  className="button-primary button-big"
                >
                  {t("register")}
                </a>
                <button
                  onClick={() => navigate("/contact#contact")}
                  className="button-tertiary button-big"
                >
                  {t("scheduleConsulting")}
                </button>{" "}
              </div>
            </div>
            <div className="flex flex-row items-center gap-[50px]">
              <div className="lg:justify-start justify-center items-center gap-5 inline-flex flex-wrap">
                <a
                  href="https://www.metatrader5.com/en"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={metaTraderIcon} alt="" />
                </a>
                <a
                  href="https://download.mql5.com/cdn/web/metaquotes.software.corp/mt5/mt5setup.exe?utm_source=www.metatrader5.com&amp;utm_campaign=download"
                  className="flex items-center gap-[12px]"
                >
                  <img src={windowsIcon} alt="Windows Icon" />
                  <span className="text-[15px] leading-[23px] text-[#788497]">
                    Windows App
                  </span>
                </a>
                <a
                  className="flex items-center gap-[12px]"
                  href="https://download.mql5.com/cdn/web/metaquotes.software.corp/mt5/MetaTrader5.pkg.zip?utm_source=www.metatrader5.com&utm_campaign=download.mt5.macos"
                >
                  <img src={macOsIcon} alt="Mac Os Icon" className="w-[20px]" />
                  <span className="text-[15px] leading-[23px] text-[#788497]">
                    MacOS App
                  </span>
                </a>
                <a
                  className="flex items-center gap-[12px]"
                  href="https://www.mql5.com/en/articles/625?utm_source=www.metatrader5.com&utm_campaign=download.mt5.linux"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={linuxIcon} alt="Linux Icon" />
                  <span className="text-[15px] leading-[23px] text-[#788497]">
                    Linux App
                  </span>
                </a>
              </div>
            </div>
          </div>

          <div className="flex flex-row items-center justify-center relative">
            <img
              src={laptopImg}
              alt="bg"
              className="2xl:w-auto lg:h-[458px] w-auto"
            />
          </div>
        </div>
      </div>
      <div className="pt-[28px]">
        <StocksSLider shouldBackground />
      </div>
    </div>
  );
}

export default ForexCFD;
