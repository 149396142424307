import { useTranslation } from "react-i18next";

function TFCapitalAd() {
  const { t } = useTranslation("contact");
  return (
    <div className="flex flex-col gap-[51px] max-w-[351px]">
        <div className="text-[30px] leading-[42px]">
            <h1 className="font-extrabold text-[#3E6BE4]">{t("tfCapitalAd.titleOne")}</h1>
            <h1 className="font-medium">{t("tfCapitalAd.titleTwo")}</h1>
        </div>
        <p className="text-[18] leading-[28px] text-[#788497]">{t("tfCapitalAd.text")}</p>
    </div>
  );
}

export default TFCapitalAd;
