import { useEffect, useState } from "react";
import i18n from "../../locales/i18n";
import { useTranslation } from "react-i18next";

function LanguageSwitcher() {
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const { t } = useTranslation("header");

  useEffect(() => {
    const handleLanguageChange = (newLang) => {
      setCurrentLanguage(newLang);
    };

    i18n.on("languageChanged", handleLanguageChange);

    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, []);

  return (
    <div className="flex items-center gap-[5px] text-sm leading-[17px] text-[#cbcbcb]">
      <p
        className={`${currentLanguage === "me" ? "text-[#3E6BE4] font-bold" : "hover:text-[#3E6BE4] transition-colors ease-in-out duration-200"
          } cursor-pointer`}
        onClick={() => changeLanguage("me")}
      >
        {t("me")}
      </p>
      <p>/</p>
      <p
        className={`${currentLanguage === "en" ? "text-[#3E6BE4] font-bold" : "hover:text-[#3E6BE4] transition-colors ease-in-out duration-200"
          } cursor-pointer`}
        onClick={() => changeLanguage("en")}
      >
        {t("en")}
      </p>
    </div>
  );
}

export default LanguageSwitcher;
