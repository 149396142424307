function TermsAndConditions() {
  return (
    <>
      <div className="container mx-auto lg:px-[20px] px-[16px] lg:py-[80px] py-[60px]">
        <h1 className="font-bold text-[48px] mb-[50px] text-black text-center">
          Terms and Conditions
        </h1>
        <div className="text-[#585768] text-[16px] leading-[28px] mb-[40px]">
          <p>
            TF Capital Markets is regulated by the Montenegrin Capital Market
            Authority with License number: 03/2-295/6-22
          </p>
          <p>Date of Incorporation: 22 June 2022</p>
          <p>Company registration number: 4 – 0009835</p>
          <p>Tax identification number: 03448398</p>
          <p>LEI number: 2549002S7C53NMXWFI72</p>
          <p>
            Address: Rimski trg 4, zgrada br. 1, VI sprat, 81000, Podgorica.
          </p>
          <p>Phone: +382 020 690 525</p>
          <p>E-mail: info@tfcapital.me</p>
        </div>
        <div className="text-[#585768] text-[16px] leading-[28px] mb-[40px]">
          <h2 className="text-[24px] font-bold text-black mb-[20px]">
            TERMS OF USE
          </h2>
          <p>
            Please read these terms and conditions carefully. By accessing this
            World Wide Website ("Web Site") and any pages hereof, you are
            indicating that you have read, acknowledge and agree to be bound by
            these Terms of Use. If you do not agree to these Terms of Use, do
            not access this Web Site. TF Capital Markets reserves the right to
            change these Terms of Use which you are responsible for regularly
            reviewing and your continued use of this Web Site constitutes
            agreement to all such changes.
          </p>
        </div>
        <div className="text-[#585768] text-[16px] leading-[28px] mb-[40px]">
          <h2 className="text-[24px] font-bold text-black mb-[20px]">
            Web Content and Materials
          </h2>
          <p>
            The information and materials contained in this Web Site - and the
            terms and conditions of the access to and use of such information
            and materials - are subject to change without notice. Not all
            products and services are available in all geographic areas. Your
            eligibility for particular products and services is subject to final
            TF Capital Markets determination and acceptance. TF CAPITAL MARKETS
            OR ITS SUPPLIERS MAY DISCONTINUE OR MAKE CHANGES IN THE INFORMATION,
            PRODUCTS OR SERVICES DESCRIBED HEREIN AT ANY TIME. ANY DATED
            INFORMATION IS PUBLISHED AS OF ITS DATE ONLY, AND TF CAPITAL MARKETS
            DOES NOT UNDERTAKE ANY OBLIGATION OR RESPONSIBILITY TO UPDATE OR
            AMEND ANY SUCH INFORMATION. TF CAPITAL MARKETS RESERVES THE RIGHT TO
            TERMINATE ANY OR ALL WEB OFFERINGS WITHOUT PRIOR NOTICE TO THE USER.
            FURTHERMORE, BY OFFERING INFORMATION, PRODUCTS OR SERVICES VIA THIS
            WEB SITE, NO SOLICITATION IS MADE BY TF CAPITAL MARKETS TO ANY
            PERSON TO USE SUCH INFORMATION, PRODUCTS OR SERVICES IN
            JURISDICTIONS WHERE THE PROVISION OF SUCH INFORMATION, PRODUCTS OR
            SERVICES IS PROHIBITED BY LAW.
          </p>
        </div>
        <div className="text-[#585768] text-[16px] leading-[28px] mb-[40px]">
          <h2 className="text-[24px] font-bold text-black mb-[20px]">
            Links to Other Sites
          </h2>
          <p>
            Links to non-TF CAPITAL MARKETS web sites are provided solely as
            pointers to information on topics that may be useful to users of the
            TF CAPITAL MARKETS Web Site, and TF CAPITAL MARKETS has no control
            over the content on such non-TF CAPITAL MARKETS web sites. If you
            choose to link to a web site not controlled by TF CAPITAL MARKETS,
            TF CAPITAL MARKETS makes no warranties, either express or implied,
            concerning the content of such web site, including the accuracy,
            completeness, reliability or suitability thereof for any particular
            purpose, nor does TF CAPITAL MARKETS warrant that such web site or
            content is free from any claims of copyright, trademark or other
            infringement of the rights of third parties or that such web site or
            content is devoid of viruses or other contamination. TF CAPITAL
            MARKETS does not guarantee the authenticity of documents on the
            Internet. Links to non-TF CAPITAL MARKETS web sites do not imply any
            endorsement of or responsibility for the opinions, ideas, products,
            information or services offered at such web sites, or any
            representation regarding the content at such web sites. When using a
            link to a web site external to TF CAPITAL MARKETS, you expressly
            acknowledge that you are visiting a site which is not operated or
            controlled by TF CAPITAL MARKETS. Neither TF CAPITAL MARKETS, any of
            its affiliates, nor their respective directors, members, officers or
            employees endorses the information provided on such external web
            sites, nor makes any recommendation, representation or warranty nor
            accepts any liability to you or any other party for any direct or
            indirect damages including, without limitation, trading losses
            resulting from futures trades entered through such site or resulting
            from the inability to use such site, arising out of your use, or
            your inability to use, such external site, regardless of the form of
            action, even if TF CAPITAL MARKETS has been advised of or otherwise
            might have anticipated the possibility of such damages. You visit
            and use these external web sites at your own risk.
          </p>
        </div>
        <div className="text-[#585768] text-[16px] leading-[28px] mb-[40px]">
          <h2 className="text-[24px] font-bold text-black mb-[20px]">
            Limitation of Liability
          </h2>
          <p>
            BECAUSE OF THE POSSIBILITY OF HUMAN AND MECHANICAL ERROR AS WELL AS
            OTHER FACTORS, TF CAPITAL MARKETS IS NOT RESPONSIBLE FOR ANY ERRORS
            IN OR OMISSIONS FROM THE INFORMATION CONTAINED IN OR ACCESSED
            THROUGH THIS WEB SITE. ALL SUCH INFORMATION IS PROVIDED "AS IS" TO
            THE USER WITHOUT EXPRESS OR IMPLIED WARRANTIES OF ANY KIND INCLUDING
            THE WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT OF INTELLECTUAL
            PROPERTY OR FITNESS FOR ANY PARTICULAR PURPOSE. FURTHERMORE, TF
            CAPITAL MARKETS WILL NOT BE LIABLE FOR ANY DELAY, DIFFICULTY IN USE,
            INACCURACY OF INFORMATION, COMPUTER VIRUSES, MALICIOUS CODE OR OTHER
            DEFECT IN THIS WEB SITE, OR FOR THE INCOMPATIBILITY BETWEEN THIS WEB
            SITE AND FILES AND THE USER'S BROWSER OR OTHER SITE ACCESSING
            PROGRAM. NOR WILL TF CAPITAL MARKETS BE LIABLE FOR ANY OTHER
            PROBLEMS EXPERIENCED BY THE USER DUE TO CAUSES BEYOND TF CAPITAL
            MARKETS'S CONTROL. NO LICENSE TO THE USER IS IMPLIED IN THESE
            DISCLAIMERS. EXCEPT AS MAY BE OTHERWISE EXPRESSLY PROVIDED BY
            WRITTEN AGREEMENT BETWEEN TF CAPITAL MARKETS AND THE USER, TF
            CAPITAL MARKETS WILL HAVE NO TORT, CONTRACT OR ANY OTHER LIABILITY
            TO THE USER AND/OR ANY THIRD PARTY ARISING IN CONNECTION WITH THE
            USE OF THIS WEB SITE, OR RELIANCE ON ANY INFORMATION OR SERVICES
            PROVIDED AT THIS WEB SITE. TF CAPITAL MARKETS WILL UNDER NO
            CIRCUMSTANCES BE LIABLE TO THE USER AND/OR ANY THIRD PARTY,
            REGARDLESS OF THE FORM OF ACTION, FOR ANY LOST PROFITS OR LOST
            OPPORTUNITY, OR ANY INDIRECT, SPECIAL, CONSEQUENTIAL, INCIDENTAL OR
            PUNITIVE DAMAGES WHATSOEVER, EVEN IF TF CAPITAL MARKETS HAS BEEN
            ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          </p>
        </div>
      </div>
    </>
  );
}

export default TermsAndConditions;
