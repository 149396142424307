import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

function CookieModal({ show, handleAccept }) {
	const { t } = useTranslation("home");

	return (
		<div
			id="cookies-modal"
			tabIndex="-1"
			aria-hidden="false"
			className={`${show
				? "z-[99999999999999] translate-y-0"
				: "z-[-9999999999999] translate-y-[100%]"
				} flex transition-all ease-in-out duration-300 overflow-y-auto overflow-x-hidden fixed bottom-0 left-0 transform  z-[99999999] justify-center items-center w-full md:bottom-0`}
		>
			<div className="relative w-full h-full md:h-auto">
				<div className="relative bg-[#293B84] shadow flex flex-col items-center lg:flex-row lg:justify-center">
					<div className="py-6 font-light text-gray-600 max-w-[80%]">
						<p className="mb-4 text-[14px] font-bold text-white">
							{t("cookies.title")}
						</p>
						<p className="mb-2 text-white text-[14px]">{t("cookies.content")}
							<NavLink to="/privacyPolicy" className="px-1 hover:underline font-bold">
								{t("cookies.privacyPolicy")}
							</NavLink>
							<NavLink to="/termsConditions" className="hover:underline font-bold">
								{t("cookies.termsOfService")}
							</NavLink>
						</p>
					</div>
					<div className="justify-between items-center pl-4 pt-0 space-y-4 sm:flex sm:space-y-0 lg:mt-[24px] mb-4 z-[99999999]">
						<div className="items-center space-y-4 sm:space-x-4 sm:flex sm:space-y-0">
							<button
								onClick={handleAccept}
								className="px-[48px] py-[12.5px] bg-[#3E6BE4] text-white text-xs font-semibold leading-[15px]"
							>
								{t("cookies.button")}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CookieModal;
