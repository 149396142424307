import HeroSection from "../components/Contact/HeroSection";
import CapitalProducts from "../components/CapitalProducts/CapitalProducts";
import ContactSectionNew from "../components/ContactSectionNew/ContactSectionNew";
import MapLocation from "../components/MapLocation/MapLocation";
import DirectorSection from "../components/Contact/DirectorSection";
import TFCapitalAd from "../components/Contact/TFCapitalAd";
function Contact() {
  return (
    <>
      <HeroSection />
      {/* <div id="contact">
        <ContactSection />
      </div> */}
      <div
        id="contact"
        className="container mx-auto lg:px-[20px] px-[16px] pb-[50px]"
      >
        <ContactSectionNew showTitle={false} />
      </div>
      <div
        id="map"
        className="container mx-auto lg:px-[20px] px-[16px] lg:pt-[50px] lg:pb-[100px] py-[64px] flex lg:flex-row flex-col lg:gap-[100px] gap-[72px] lg:items-center lg:justify-center"
      >
        <TFCapitalAd />
        <MapLocation />
      </div>
      <DirectorSection />
      <div className="lg:pt-[60px] pt-0  lg:pb-16 pb-12 overflow-x-hidden">
        <CapitalProducts />
      </div>
    </>
  );
}

export default Contact;
