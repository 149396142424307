function EduChooseTheme() {
  return <div className="relative z-30 w-full items-center justify-center lg:gap-[44px] gap-[40px] flex flex-col lg:py-[100px] py-[50px]">
    <h2 className="text-center lg:text-[48px] lg:leading-[48px] text-[32px] leading-[32px] font-semibold text-black">
        Izaberi temu edukacije
    </h2>
    <div className="flex items-center flex-wrap justify-center gap-[30px]">
        <div className="cursor-pointer w-[213px] border-[1px] border-[#788497] uppercase transform -skew-x-[4deg] py-[35px] px-[36px] text-[21px] leading-[30px] text-center font-semibold text-black">
            <p className="transform skew-x-[4deg]">
            Forex
            </p>
        </div>
        <div className="cursor-pointer w-[213px] border-[1px] border-[#788497] uppercase transform -skew-x-[4deg] py-[35px] px-[36px] text-[21px] leading-[30px] text-center font-semibold text-black">
            <p className="transform skew-x-[4deg]">
            Forex
            </p>
        </div>
        <div className="cursor-pointer w-[213px] border-[1px] border-[#788497] uppercase transform -skew-x-[4deg] py-[35px] px-[36px] text-[21px] leading-[30px] text-center font-semibold text-black">
            <p className="transform skew-x-[4deg]">
            Forex
            </p>
        </div>
        <div className="cursor-pointer w-[213px] border-[1px] border-[#788497] uppercase transform -skew-x-[4deg] py-[35px] px-[36px] text-[21px] leading-[30px] text-center font-semibold text-black">
            <p className="transform skew-x-[4deg]">
            Forex
            </p>
        </div>
        <div className="cursor-pointer w-[213px] border-[1px] border-[#788497] uppercase transform -skew-x-[4deg] py-[35px] px-[36px] text-[21px] leading-[30px] text-center font-semibold text-black">
            <p className="transform skew-x-[4deg]">
            Forex
            </p>
        </div>
        <div className="cursor-pointer w-[213px] border-[1px] border-[#788497] uppercase transform -skew-x-[4deg] py-[35px] px-[36px] text-[21px] leading-[30px] text-center font-semibold text-black">
            <p className="transform skew-x-[4deg]">
            Forex
            </p>
        </div>
    </div>
  </div>;
}

export default EduChooseTheme;
