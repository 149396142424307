import iphonesImg from "../../assets/phones-blog.png";
import metatraderLogo from "../../assets/metatrader_logo.svg";
import numbersContainerImg from "../../assets/number_container_2.png";
import forexBg from "../../assets/forex-cover-card.png";
import laptopImg from "../../assets/laptopmobile.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, A11y } from "swiper/modules";
import { useTranslation } from "react-i18next";

import TitleSection from "../TitleSection/TitleSection";
import { NavLink } from "react-router-dom";

function CapitalProducts() {
  const { t } = useTranslation("capitalProducts");

  return (
    <div className="overflow-hidden lg:pb-[40px] pb-[60px] capital-products-slider w-full flex flex-col gap-[50px] relative z-[999]">
      <TitleSection
        title={t("title")}
        subtitle={t("subtitle")}
        showTitleSecond
      />
      <div className="flex relative lg:min-w-full w-full lg:gap-0 gap-[20px]">
        <div
          style={{
            background:
              "linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)",
          }}
          className="xl:block hidden w-[280px] h-full absolute bg-gradient-to-r from-white via-rgba-white to-white z-[999] top-0 right-0"
        ></div>
        <div
          style={{
            background:
              "linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)",
          }}
          className="xl:block hidden w-[280px] h-full absolute bg-gradient-to-r from-white via-rgba-white to-white z-[999] top-0 left-0"
        ></div>
        <Swiper
          modules={[Pagination, A11y]}
          spaceBetween={0}
          slidesPerView={"auto"}
          centeredSlides={true}
          autoHeight
          pagination={{ clickable: true }}
          initialSlide={1}
          onSwiper={(swiper) => {
            window.mySwiper = swiper;
          }}
        >
          <SwiperSlide onClick={() => window.mySwiper.slideTo(0)}>
            <div className="lg:w-[750px]  w-full ">
              <div className="relative flex lg:pl-[48px] lg:h-[285px] h-auto  lg:min-w-[750px] w-full lg:flex-row flex-col">
                <div className="lg:order-1 order-2 transform lg:-skew-x-[9deg] border-2 border-[#C8E64C] lg:border-r-0 border-r-2 lg:border-t-2 border-t-0 bg-[#F4F7FF] shadow-xl ">
                  <div className="lg:skew-x-[9deg] transform lg:pt-[45px] pt-[28px] lg:pb-[19px] 2xl:pb-[26px] pb-[54px] flex flex-col 2xl:gap-[68px] lg:gap-[51px] gap-[24px]">
                    <div className="lg:pl-[72px] px-[14px] items-start flex flex-col lg:gap-[20px] gap-[20px]">
                      <h3
                        dangerouslySetInnerHTML={{
                          __html: t("secondSlide.title"),
                        }}
                        className="w-full font-medium text-[20px] leading-[26px] text-black"
                      />
                      <p className="lg:max-w-[276px] text-[#788497]  text-[12px] leading-[19px]">
                        {t("secondSlide.subtitle")}
                      </p>
                      <button className="button-primary button-small lg:mx-0 mx-auto">
                        {t("secondSlide.button")}
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    backgroundImage: `url(${forexBg})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                  className="border-2 border-[#C8E64C] lg:border-l-0 border-b-0 lg:border-b-[2px] lg:order-2 order-1 transform lg:-skew-x-[9deg] lg:w-[340px] lg:min-h-full w-full lg:h-auto h-[228px] relative flex items-center justify-center"
                >
                  <img src={laptopImg} alt="laptop" />
                  <img
                    src={metatraderLogo}
                    alt="metatraderLogo"
                    className="absolute bottom-[10px] lg:left-[30px] left-1/2 lg:translate-x-0 -translate-x-1/2 transform lg:skew-x-[9deg]"
                  />
                </div>
                <div className="absolute z-20 lg:w-auto w-[222px] lg:left-[40px] bottom-[10px] left-1/2 transform lg:translate-x-0 -translate-x-1/2 flex items-center gap-2">
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.49265 1.69434C4.28066 1.69434 1.67383 4.30231 1.67383 7.5157C1.67383 10.7291 4.28066 13.3371 7.49265 13.3371C10.7046 13.3371 13.3115 10.7291 13.3115 7.5157C13.3115 4.30231 10.7046 1.69434 7.49265 1.69434ZM8.07453 10.4264H6.91077V6.93357H8.07453V10.4264ZM8.07453 5.76929H6.91077V4.60502H8.07453V5.76929Z"
                      fill="#788497"
                    />
                  </svg>
                  <p className="lg:text-[8px] lg:leading-[12px] text-[10px] leading-[16px] text-gray-600">
                    {t("secondSlide.footer")}
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide onClick={() => window.mySwiper.slideTo(1)}>
            <div className="lg:w-[750px]  w-full ">
              <div className="relative flex lg:pl-[48px] lg:h-[285px] h-auto  lg:min-w-[750px] w-full lg:flex-row flex-col">
                <div className="lg:order-1 order-2 transform lg:-skew-x-[9deg] border-2 border-[#C8E64C] lg:border-r-0 border-r-2 lg:border-t-2 border-t-0 bg-[#F4F7FF] shadow-xl ">
                  <div className="lg:skew-x-[9deg] transform lg:pt-[45px] pt-[28px] lg:pb-[19px] 2xl:pb-[26px] pb-[54px] flex flex-col 2xl:gap-[68px] lg:gap-[51px] gap-[24px]">
                    <div className="lg:pl-[72px] px-[14px] items-start flex flex-col lg:gap-[20px] gap-[16px]">
                      <h3
                        dangerouslySetInnerHTML={{
                          __html: t("firstSlide.title"),
                        }}
                        className="w-full font-medium text-[20px] leading-[26px] text-black"
                      />
                      <p
                        className="lg:max-w-[276px] text-[#788497]  text-[12px] leading-[19px]"
                        dangerouslySetInnerHTML={{
                          __html: t("firstSlide.subtitle"),
                        }}
                      />
                      <NavLink
                        to="/usa-berza"
                        className="button-small button-primary lg:mx-0 mx-auto"
                      >
                        {t("firstSlide.button")}
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    background:
                      "radial-gradient(40.28% 58.53% at 47.74% 51.51%, #467AFF 0%, #1A2F86 100%)",
                    mixBlendMode: "multiply",
                  }}
                  className="border-2 border-[#C8E64C] lg:border-l-0 border-b-0 lg:border-b-[2px] overflow-hidden lg:order-2 order-1 transform lg:-skew-x-[9deg] lg:w-[340px] lg:min-h-full w-full min-w-[295px] lg:h-auto h-[228px] relative flex items-center justify-center"
                >
                  <svg
                    className="absolute bottom-0 z-10 -left-0 transform lg:skew-x-[9deg]"
                    width="335"
                    height="236"
                    viewBox="0 0 335 236"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M-89.7784 101.407L-111.074 117.367V236.531H397.896V0.0766602L384.513 11.2953L381.06 5.90171L368.325 24.2398L364.655 20.1407L360.338 21.4352L359.259 17.7675L353.647 20.9004L348.035 29.4176L345.229 25.75L340.912 35.2427L326.881 45.7263L323.763 42.9659L318.794 45.7263L301.403 21.9644L292.175 33.3134L288.549 29.0575L282.237 34.8088L273.136 29.4176L269.682 21.4352L265.507 29.0575L263.377 26.5749L254.504 50.3368L250.955 43.953L244.211 52.1101L239.242 50.3368L231.434 54.238L226.11 50.3368L212.622 66.6509L206.589 62.0404L189.197 79.0638L185.293 64.8776L167.546 54.238L161.157 46.4356L134.893 64.8776L125.309 58.8485L115.371 62.0404L110.757 50.3368L98.6897 70.9068L64.9713 91.4767L52.9036 75.5173L20.25 86.5116L7.11761 75.5173L-23.4064 91.4767L-34.7642 75.5173L-51.4459 91.4767H-57.1248L-78.4206 108.146L-89.7784 101.407Z"
                      fill="url(#paint0_radial_4686_286)"
                      fillOpacity="0.9"
                    />
                    <defs>
                      <radialGradient
                        id="paint0_radial_4686_286"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(159.6 74.9393) rotate(-0.312458) scale(146.403 125.822)"
                      >
                        <stop stopColor="#C8E64C" />
                        <stop
                          offset="0.761075"
                          stopColor="#C8E64C"
                          stopOpacity="0.29"
                        />
                        <stop offset="1" stopColor="#C8E64C" stopOpacity="0" />
                      </radialGradient>
                    </defs>
                  </svg>

                  <img
                    src={iphonesImg}
                    alt="numbersContainerImg"
                    className="lg:skew-x-[9deg]  w-[185px] relative z-20"
                  />
                </div>
                <div className="absolute z-20 lg:w-auto w-[222px] lg:left-[40px] bottom-[10px] left-1/2 transform lg:translate-x-0 -translate-x-1/2 flex items-center gap-2">
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.49265 1.69434C4.28066 1.69434 1.67383 4.30231 1.67383 7.5157C1.67383 10.7291 4.28066 13.3371 7.49265 13.3371C10.7046 13.3371 13.3115 10.7291 13.3115 7.5157C13.3115 4.30231 10.7046 1.69434 7.49265 1.69434ZM8.07453 10.4264H6.91077V6.93357H8.07453V10.4264ZM8.07453 5.76929H6.91077V4.60502H8.07453V5.76929Z"
                      fill="#788497"
                    />
                  </svg>
                  <p className="lg:text-[8px] lg:leading-[12px] text-[10px] leading-[16px] text-gray-600">
                    {t("firstSlide.footer")}
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide onClick={() => window.mySwiper.slideTo(2)}>
            <div className="lg:w-[750px]  w-full ">
              <div className="relative flex lg:pl-[48px] lg:h-[285px] h-auto  lg:min-w-[750px] w-full lg:flex-row flex-col">
                <div className="lg:order-1 order-2 transform lg:-skew-x-[9deg] border-2 border-[#C8E64C] lg:border-r-0 border-r-2 lg:border-t-2 border-t-0 bg-[#F4F7FF] shadow-xl ">
                  <div className="lg:skew-x-[9deg] transform lg:pt-[45px] pt-[28px] lg:pb-[19px] 2xl:pb-[26px] pb-[54px] flex flex-col 2xl:gap-[68px] lg:gap-[51px] gap-[24px]">
                    <div className="lg:pl-[72px] px-[14px] items-start flex flex-col lg:gap-[20px] gap-[16px]">
                      <h3
                        dangerouslySetInnerHTML={{
                          __html: t("thirdSlide.title"),
                        }}
                        className="w-full font-medium text-[20px] leading-[26px] text-black"
                      />
                      <p className="lg:max-w-[226px] text-[#788497]  text-[12px] leading-[19px]">
                        {t("thirdSlide.subtitle")}
                      </p>
                      <NavLink
                        to="/montenegroberze"
                        className="button-small button-primary lg:mx-0 mx-auto"
                      >
                        {t("thirdSlide.button")}
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className="border-2 border-[#C8E64C] lg:border-l-0 border-b-0 lg:border-b-[2px] montenegro-stock-bg lg:order-2 order-1 transform lg:-skew-x-[9deg] lg:w-[340px] lg:min-h-full w-full min-w-[295px] lg:h-auto h-[228px] relative flex items-center justify-center">
                  <img
                    src={numbersContainerImg}
                    alt="numbersContainerImg"
                    className="lg:skew-x-[9deg]  2xl:w-auto lg:w-[300px] h-auto w-[300px]"
                  />
                </div>
                <div className="absolute z-20 lg:w-auto w-[222px] lg:left-[40px] bottom-[10px] left-1/2 transform lg:translate-x-0 -translate-x-1/2 flex items-center gap-2">
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.49265 1.69434C4.28066 1.69434 1.67383 4.30231 1.67383 7.5157C1.67383 10.7291 4.28066 13.3371 7.49265 13.3371C10.7046 13.3371 13.3115 10.7291 13.3115 7.5157C13.3115 4.30231 10.7046 1.69434 7.49265 1.69434ZM8.07453 10.4264H6.91077V6.93357H8.07453V10.4264ZM8.07453 5.76929H6.91077V4.60502H8.07453V5.76929Z"
                      fill="#788497"
                    />
                  </svg>
                  <p className="lg:text-[8px] lg:leading-[12px] text-[10px] leading-[16px] text-gray-600">
                    {t("thirdSlide.footer")}
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}

export default CapitalProducts;
