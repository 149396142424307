import { useState } from "react";
import QuestionTabCard from "../QuestionTabCard/QuestionTabCard";

import TitleSection from "../TitleSection/TitleSection";

function QuestionTabs({
  data,
  title,
  subtitle,
  subtitleColor = "text-[#788497]",
  showTitleSecond = false,
}) {
  const [activeTab, setActiveTab] = useState(null);

  const handleTabChange = (tab) => {
    if (tab === activeTab) setActiveTab(null);
    else setActiveTab(tab);
  };

  return (
    <div className="w-full flex flex-col gap-[64px] py-[64px] relative z-[999]">
      <TitleSection
        title={title}
        subtitle={subtitle}
        subtitleColor={subtitleColor}
        showTitleSecond={showTitleSecond}
      />
      <div className="flex flex-col gap-[10px] ">
        {data.map((item, index) => {
          return (
            <QuestionTabCard
              handleTabChange={handleTabChange}
              activeTab={activeTab}
              key={index}
              tab={index}
              question={item.question}
              answer={item.answer}
            />
          );
        })}
      </div>
    </div>
  );
}

export default QuestionTabs;
