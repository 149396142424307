function StocksSLider({ shouldBackground = false }) {
  return (
    <div className={`w-full flex items-center justify-center gap-2 flex-col`}>
      <iframe
        scrolling="no"
        allowtransparency="true"
        frameBorder="0"
        src="https://www.tradingview-widget.com/embed-widget/ticker-tape/?locale=en#%7B%22symbols%22%3A%5B%7B%22description%22%3A%22%22%2C%22proName%22%3A%22FX_IDC%3AEURUSD%22%7D%2C%7B%22description%22%3A%22%22%2C%22proName%22%3A%22FX_IDC%3AUSDJPY%22%7D%2C%7B%22description%22%3A%22%22%2C%22proName%22%3A%22FX_IDC%3AGBPUSD%22%7D%2C%7B%22description%22%3A%22%22%2C%22proName%22%3A%22FX_IDC%3AUSDCHF%22%7D%2C%7B%22description%22%3A%22%22%2C%22proName%22%3A%22TVC%3AUSOIL%22%7D%2C%7B%22description%22%3A%22%22%2C%22proName%22%3A%22FX_IDC%3AXAUUSD%22%7D%5D%2C%22showSymbolLogo%22%3Atrue%2C%22isTransparent%22%3Afalse%2C%22displayMode%22%3A%22adaptive%22%2C%22colorTheme%22%3A%22light%22%2C%22width%22%3A%22100%25%22%2C%22height%22%3A78%2C%22utm_source%22%3A%22%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22ticker-tape%22%2C%22page-uri%22%3A%22__NHTTP__%22%7D"
        title="ticker tape TradingView widget"
        lang="en"
        className="lg:h-[44px] h-[76px]"
        style={{
          userSelect: "none",
          boxSizing: "border-box",
          display: "block",
          width: "100%",
        }}
      ></iframe>
      <a
        href="https://www.tradingview.com/"
        target="_blank"
        className="text-[14px] text-blue-700 font-medium hover:text-blue-900"
      >
        Track all markets on TradingView
      </a>
    </div>
  );
}

export default StocksSLider;
