import { httpClient } from "../httpClient";

export const signup = async (data) => {
    const response = await httpClient.post('/newsletter', data);
    return response;
};

export const getContacts = async () => {
    const response = await httpClient.get('/newsletter');
    return response;
};
