import CapitalProducts from "../components/CapitalProducts/CapitalProducts";
import EduChooseTheme from "../components/EduChooseTheme/EduChooseTheme";
import EducationHero from "../components/EducationHero/EducationHero";
import EducationSearch from "../components/EducationSearch/EducationSearch";
import Newsletter from "../components/Newsletter/Newsletter";

function Education() {
  return (
    <>
      <div className="container mx-auto lg:px-[20px] px-[16px]">
        <EducationHero />
      </div>
      <EducationSearch />
      <div className="container mx-auto lg:px-[20px] px-[16px]">
        <EduChooseTheme />
      </div>
      <Newsletter />
      <div className="2xl:pt-[100px] 2xl:pb-[180px] lg:pt-[75px] lg:pb-[150px] pt-[100px] pb-[160px]">
        <CapitalProducts />
      </div>{" "}
    </>
  );
}

export default Education;
