import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/logo.svg";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { Fade as Hamburger } from "hamburger-react";
import arrowDownIcon from "../../assets/arrow_down.svg";
import forexNavImg from "../../assets/forex-nav.png";
import usaNavImg from "../../assets/usaberze-nav.png";
import montenegroNavImg from "../../assets/montenegroberze-nav.png";

function Header() {
  const { t } = useTranslation("header");
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  const [productsOpen, setProductsOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (isMenuOpened) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isMenuOpened]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      setIsHeaderFixed(scrollPosition > 32);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      id="header"
      className={`${isHeaderFixed
        ? `${isMenuOpened
          ? "bg-white backdrop-filter-none"
          : "bg-white/50 backdrop-filter"
        }    backdrop-blur-sm`
        : `${isMenuOpened ? "bg-white backdrop-filter-none" : "bg-transparent"
        } shadow-none`

        }  transition-colors duration-500 ease-in-out fixed top-0 left-0 w-full z-[9999]`}
    >
      <div className="container mx-auto lg:px-[20px] px-[16px]">
        <div className=" flex items-center justify-between 2xl:pt-[25px] 2xl:pb-[25px] py-[16px] lg:pt-[30px] lg:pb-[25px] ">
          <NavLink to="/">
            <img
              src={logo}
              alt="logo"
              className="2xl:w-full md:w-[100px] w-[90px]"
            />
          </NavLink>
          <div className=" h-[40px] lg:flex relative z-[9999] gap-[31px] items-center hidden text-black font-medium lg:text-[14px] lg:leading-[16.94px] 2xl:text-base 2xl:leading-[20px]">
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? "font-bold text-[#345BCC]"
                  : "hover:text-[#345BCC] transition-colors ease-in-out duration-200"
              }
              to="/"
            >
              {t("home")}
            </NavLink>

            <div className="h-full flex items-center gap-2.5 cursor-pointer group relative">
              <p
                className={`hover:text-[#345BCC] transition-colors ease-in-out duration-200 ${location.pathname === "/usa-berza" ||
                  location.pathname === "/forex" ||
                  location.pathname === "/montenegroberze"
                  ? "font-bold text-[#3E6BE4]"
                  : ""

                  }`}
              >
                {t("products")}
              </p>
              <img className="w-[13px]" src={arrowDownIcon} alt="arrow_down" />
              <div
                style={{
                  background:
                    "linear-gradient(90deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.70) 14.58%, rgba(255, 255, 255, 0.95) 50%, rgba(255, 255, 255, 0.70) 85.42%, rgba(255, 255, 255, 0.30) 100%)",
                  backdropFilter: "blur(5px)",
                }}
                className="absolute flex flex-col gap-3 top-[40px] left-0 bg-white w-[597px] h-auto p-6 opacity-0 group-hover:opacity-100 z-[-9999] pointer-events-none group-hover:pointer-events-auto group-hover:z-200"
              >
                <NavLink to="/usa-berza" className="flex gap-6 items-center">
                  <img
                    className="w-[185px] h-[105px] object-cover"
                    src={usaNavImg}
                    alt="usa"
                  />
                  <div className="flex flex-col gap-1 text-[14px] leading-[21px] text-black">
                    <p
                      className={`text-base leading-[24px]  ${location.pathname === "/usa-berza"
                        ? "text-[#3E6BE4] font-bold"
                        : "font-semibold"

                        }`}
                    >
                      {t("submenu.titleOne")}
                    </p>
                    <p>{t("submenu.textOne")}</p>
                    <NavLink
                      className="underline underline-offset-2 text-[#3E6BE4]"
                      to="/usa-berza"
                    >
                      {t("submenu.button")}
                    </NavLink>
                  </div>
                </NavLink>
                <NavLink to="/forex" className="flex gap-6 items-center">
                  <img
                    className="w-[185px] h-[105px] object-cover"
                    src={forexNavImg}
                    alt="forex"
                  />
                  <div className="flex flex-col gap-1 text-[14px] leading-[21px] text-black">
                    <p
                      className={`text-base leading-[24px]  ${location.pathname === "/forex"
                        ? "text-[#3E6BE4] font-bold"
                        : "font-semibold"
                        }`}
                    >
                      {t("submenu.titleTwo")}
                    </p>
                    <p>{t("submenu.textTwo")}</p>
                    <NavLink
                      className="underline underline-offset-2 text-[#3E6BE4]"
                      to="/forex"
                    >
                      {t("submenu.button")}
                    </NavLink>
                  </div>
                </NavLink>
                <NavLink
                  to="/montenegroberze"
                  className="flex gap-6 items-center"
                >
                  <img
                    className="w-[185px] h-[105px] object-cover"
                    src={montenegroNavImg}
                    alt="montenegroNavImg"
                  />
                  <div className="flex flex-col gap-1 text-[14px] leading-[21px] text-black">
                    <p
                      className={`text-base leading-[24px] font-semibold ${location.pathname === "/montenegroberze"
                        ? "text-[#3E6BE4]"
                        : ""

                        }`}
                    >
                      {t("submenu.titleThree")}
                    </p>
                    <p>{t("submenu.textThree")}</p>
                    <NavLink
                      className="underline underline-offset-2 text-[#3E6BE4]"
                      to="/montenegroberze"
                    >
                      {t("submenu.button")}
                    </NavLink>
                  </div>
                </NavLink>
              </div>
            </div>

            {/* 
            <NavLink
              aria-disabled
              // className={({ isActive }) =>
              //   isActive
              //     ? "font-bold border-b-[1px] border-black pb-[3px]"
              //     : "pb-[3px]"
              // }
              // to="/news"
              className="pb-[3px]"
            >
              {t("news")}
            </NavLink> */}

            {/* <NavLink
              className={({ isActive }) =>
                isActive ? "font-bold text-[#345BCC]" : ""
              }
              to="/about"
            >
              {t("whyTf")}
            </NavLink> */}
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? "font-bold text-[#345BCC]"
                  : "hover:text-[#345BCC] transition-colors ease-in-out duration-200"
              }
              to="/news?filter=all"
            >
              {t("news")}
            </NavLink>

            {/* <NavLink
              // className={({ isActive }) =>
              //   isActive
              //     ? "font-bold border-b-[1px] border-black pb-[3px]"
              //     : "pb-[3px]"
              // }
              // to="/education"
              className="pb-[3px]"
            >
              {t("education")}
            </NavLink> */}

            <NavLink
              className={({ isActive }) =>
                isActive
                  ? "font-bold text-[#345BCC]"
                  : "hover:text-[#345BCC] transition-colors ease-in-out duration-200"
              }
              to="/contact"
            >
              {t("contact")}
            </NavLink>
          </div>
          <div className="hidden lg:flex items-center gap-[31px]">
            <LanguageSwitcher />
            <a href="https://my.tfcapital.me/login">
              <button className="px-[37px] py-[10px] min-w-[115px] bg-[#3E6BE4] hover:bg-[#335DCC] transition-all ease-in-out duration-200 text-white text-xs font-semibold leading-[15px]">
                {t("login")}
              </button>
            </a>
          </div>
          <div className="lg:hidden flex gap-4">
            <LanguageSwitcher />
            <div
              onClick={() => setIsMenuOpened(!isMenuOpened)}
              className="bg-[#E7EDFF] gap-[3px] cursor-pointer rounded-[50px] px-[8px] flex lg:hidden items-center justify-center flex-col"
            >
              <Hamburger
                toggled={isMenuOpened}
                size={18}
                color="#163A69"
                rounded
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${isMenuOpened
          ? "opacity-1 z-[999999] translate-x-0 "
          : "opacity-0 z-0 translate-x-[100%] "

          } transition-opacity transform  duration-500 ease-in-out lg:hidden block mt-[80px] w-screen h-[calc(100vh-80px)] bg-white absolute top-0 left-0 `}
      >
        <div className="text-[#A6A6A6] text-[16px] leading-[16px] font-medium flex flex-col text-left py-[32px] px-[16px]">
          <NavLink
            className={({ isActive }) =>
              isActive ? "font-medium text-[#3E6BE4] py-5" : "py-5"
            }
            to="/"
            onClick={() => setIsMenuOpened(false)}
          >
            {t("home")}
          </NavLink>

          <div
            onClick={() => setProductsOpen(!productsOpen)}
            className={`flex items-center justify-between py-5 cursor-pointer relative ${location.pathname === "/usa-berza" ||
              location.pathname === "/forex" ||
              location.pathname === "/montenegroberze"
              ? "font-medium text-[#3E6BE4]"
              : ""
              }`}
          >
            <p>{t("products")}</p>
            <img src={arrowDownIcon} alt="arrow_down" className="w-4" />
          </div>

          <div
            className={`relative overflow-hidden transition-all min-w-[350px] ease-in-out duration-500 w-full bg-white ${productsOpen ? "h-[300px] max-h-[300px]" : "max-h-0 h-0"
              }`}
          >
            <div
              className={`flex flex-col gap-3 absolute transition-all ease-in-out duration-500 ${productsOpen
                ? "top-0 left-0 opacity-100 pointer-events-auto"
                : "-top-[50px] left-[40px] opacity-0 pointer-events-none"

                }`}
            >
              <NavLink
                to="/usa-berza"
                className="flex gap-4 items-center"
                onClick={() => setIsMenuOpened(false)}
              >
                <img
                  className="w-[155px] h-[90px] object-cover"
                  src={usaNavImg}
                  alt="usa"
                />
                <div className="flex flex-col gap-1 text-[14px] leading-[21px] text-black">
                  <p
                    className={`text-base leading-[24px]  ${location.pathname === "/usa-berza"
                      ? "text-[#3E6BE4] font-bold"
                      : "font-semibold"

                      }`}
                  >
                    {t("submenu.titleOne")}
                  </p>
                  <p>{t("submenu.textOneMobile")}</p>
                </div>
              </NavLink>
              <NavLink
                to="/forex"
                className="flex gap-4 items-center"
                onClick={() => setIsMenuOpened(false)}
              >
                <img
                  className="w-[155px] h-[90px] object-cover"
                  src={forexNavImg}
                  alt="forex"
                />
                <div className="flex flex-col gap-1 text-[14px] leading-[21px] text-black">
                  <p
                    className={`text-base leading-[24px] ${location.pathname === "/forex"
                      ? "text-[#3E6BE4] font-bold"
                      : "font-semibold"

                      }`}
                  >
                    {t("submenu.titleTwo")}
                  </p>
                  <p>{t("submenu.textTwoMobile")}</p>
                </div>
              </NavLink>
              <NavLink
                to="/montenegroberze"
                className="flex gap-4 items-center"
                onClick={() => setIsMenuOpened(false)}
              >
                <img
                  className="w-[155px] h-[90px] object-cover"
                  src={montenegroNavImg}
                  alt="montenegroNavImg"
                />
                <div className="flex flex-col gap-1 text-[14px] leading-[21px] text-black">
                  <p
                    className={`text-base leading-[24px]  ${location.pathname === "/montenegroberze"
                      ? "text-[#3E6BE4] font-bold"
                      : "font-semibold"
                      }`}
                  >
                    {t("submenu.titleThree")}
                  </p>
                  <p>{t("submenu.textThreeMobile")}</p>
                </div>
              </NavLink>
            </div>
          </div>

          <NavLink
            className={({ isActive }) =>
              isActive ? "font-medium text-[#3E6BE4] py-5" : "py-5"
            }
            to="/news?filter=all"
            onClick={() => setIsMenuOpened(false)}
          >
            {t("news")}
          </NavLink>

          {/* <NavLink
            className={({ isActive }) =>
              isActive ? "font-medium text-[#3E6BE4] py-5" : "py-5"
            }
            to="/about"
            onClick={() => setIsMenuOpened(false)}
          >
            {t("whyTf")}
          </NavLink> */}

          {/* <NavLink
            // className={({ isActive }) =>
            //   isActive ? "font-medium text-[#3E6BE4] py-5" : "py-5"
            // }
            // to="/education"
            // onClick={() => setIsMenuOpened(false)}
          >
            {t("education")}
          </NavLink> */}

          <NavLink
            className={({ isActive }) =>
              isActive ? "font-medium text-[#3E6BE4] py-5" : "py-5"
            }
            to="/contact"
            onClick={() => setIsMenuOpened(false)}
          >
            {t("contact")}
          </NavLink>
          <a
            href="https://my.tfcapital.me/register"
            className="w-full mt-[48px]"
          >
            <button className="w-full mr-[16px] px-[37px] py-[12.5px] bg-[#3E6BE4] hover:bg-[#335DCC] transition-all ease-in-out duration-200 text-white text-xs font-semibold leading-[15px]">
              {t("login")}
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Header;
