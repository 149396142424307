import arrowDownIcon from "../../assets/arrow-icon.svg";

function QuestionTabCard({
  activeTab,
  handleTabChange,
  question,
  answer,
  tab,
}) {
  return (
    <div
      className={`${
        activeTab === tab ? "max-h-[300px]" : "h-auto max-h-0"
      } question-answer flex flex-col lg:gap-[30px] gap-[22px] min-h-[80px]`}
    >
      <div
        onClick={() => handleTabChange(tab)}
        className={`${
          activeTab === tab
            ? "bg-[#C8E64C] text-[#163A69]"
            : "question-tab-bg text-[#788497]"
        } w-full cursor-pointer transform lg:-skew-x-[8deg] relative lg:py-[21px] py-4 px-[16px] transition-all ease-in-out duration-200`}
      >
        <p className="lg:text-[14px] text-[12px] font-bold text-center lg:py-0 py-[7px] transform lg:skew-x-[8deg] pl-[32px] pr-[56px]">
          {question}
        </p>
        <img
          src={arrowDownIcon}
          alt="arrow_down"
          className={`absolute transform top-[50%] translate-y-[-50%] right-[32px]  lg:right-[15px] cursor-pointer lg:-skew-x-[-8deg] duration-200 ${
            activeTab === tab ? "-rotate-180" : ""
          }`}
        />
      </div>
      <div
        className={`text-center overflow-hidden lg:mb-[28px] mb-[16px] text-[#788497] w-[80%] mx-auto lg:text-base text-xs `}
      >
        <p dangerouslySetInnerHTML={{ __html: answer }} />
      </div>
    </div>
  );
}

export default QuestionTabCard;
