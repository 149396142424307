import TitleSection from "../TitleSection/TitleSection";
import checkIcon from "../../assets/checkIconGreen.svg";
import { useTranslation } from "react-i18next";

function ForexCards() {
  const { t } = useTranslation("forexPage");

  return (
    <div
      className="w-full "
      style={{
        background:
          "radial-gradient(50% 78.79% at 50% 32.9%, #D9DBE5 0%, #FFF 100%)",
      }}
    >
      <div className="container mx-auto px-[16px] lg:px-[20px] lg:pb-[60px] lg:pt-[100px] py-16">
        <div className="flex flex-col lg:gap-20 gap-16">
          <TitleSection
            title={t("price.title")}
            subtitle={t("price.subtitle")}
          />
          <div className="flex flex-col gap-8">
            <div className="flex lg:flex-row flex-col items-center justify-center gap-4">
              <a
                href="https://my.tfcapital.me/login"
                rel="noreferrer"
                className="lg:w-[400px] w-full border border-[#3E6BE4] p-2.5 bg-[#F4F7FF]"
              >
                <div className="forex-card relative lg:p-[52px] py-[52px] px-5 flex flex-col items-start gap-12">
                  <div className="flex flex-col gap-6">
                    <label className="text-[21px] leading-[29px] font-medium text-black">
                      {t("price.priceTitle-1")}
                    </label>
                    <div className="flex gap-1 items-end">
                      <p className="text-black text-[30px] font-bold leading-[100%]">
                        €500
                      </p>
                      <span className="text-sm leading-[20px] text-[#788497]">
                        {t("price.priceDesc")}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4">
                    <div className="flex gap-2 items-center">
                      <img src={checkIcon} alt="check" />
                      <p className="text-black text-xs">
                        {t("price.priceOption-1-1")}{" "}
                      </p>
                    </div>
                    <div className="flex gap-2 items-center">
                      <img src={checkIcon} alt="check" />
                      <p className="text-black text-xs">
                        {t("price.priceOption-1-2")}{" "}
                      </p>
                    </div>
                    <div className="flex gap-2 items-center">
                      <img src={checkIcon} alt="check" />
                      <p className="text-black text-xs">
                        {t("price.priceOption-1-3")}{" "}
                      </p>
                    </div>
                    <div className="flex gap-2 items-center">
                      <img src={checkIcon} alt="check" />
                      <p className="text-black text-xs">
                        {t("price.priceOption-1-4")}{" "}
                      </p>
                    </div>
                    <div className="flex gap-2 items-center">
                      <img src={checkIcon} alt="check" />
                      <p className="text-black text-xs">
                        {t("price.priceOption-1-5")}{" "}
                      </p>
                    </div>
                    <div className="flex gap-2 items-center">
                      <img src={checkIcon} alt="check" />
                      <p className="text-black text-xs">
                        {t("price.priceOption-1-6")}{" "}
                      </p>
                    </div>
                    <div className="flex gap-2 items-center">
                      <img src={checkIcon} alt="check" />
                      <p className="text-black text-xs">
                        {t("price.priceOption-1-7")}{" "}
                      </p>
                    </div>
                    <div className="flex gap-2 items-center">
                      <img src={checkIcon} alt="check" />
                      <p className="text-black text-xs">
                        {t("price.priceOption-1-8")}{" "}
                      </p>
                    </div>
                    <div className="flex gap-2 items-center">
                      <img src={checkIcon} alt="check" />
                      <p className="text-black text-xs">
                        {t("price.priceOption-1-9")}{" "}
                      </p>
                    </div>
                  </div>
                  <button className="button-small button-primary mx-auto">
                    {t("price.button")}
                  </button>
                </div>
              </a>
              <a
                href="https://my.tfcapital.me/login"
                rel="noreferrer"
                className="lg:w-[400px] w-full border border-[#3E6BE4] p-2.5 bg-[#F4F7FF]"
              >
                <div className="forex-card relative lg:p-[52px] py-[52px] px-5 flex flex-col items-start gap-12">
                  <div className="flex flex-col gap-6">
                    <label className="text-[21px] leading-[29px] font-medium text-black">
                      {t("price.priceTitle-2")}
                    </label>
                    <div className="flex gap-1 items-end">
                      <p className="text-black text-[30px] font-bold leading-[100%]">
                        €1.000
                      </p>
                      <span className="text-sm leading-[20px] text-[#788497]">
                        {t("price.priceDesc")}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4">
                    <div className="flex gap-2 items-center">
                      <img src={checkIcon} alt="check" />
                      <p className="text-black text-xs">
                        {t("price.priceOption-2-1")}{" "}
                      </p>
                    </div>
                    <div className="flex gap-2 items-center">
                      <img src={checkIcon} alt="check" />
                      <p className="text-black text-xs">
                        {t("price.priceOption-2-2")}{" "}
                      </p>
                    </div>
                    <div className="flex gap-2 items-center">
                      <img src={checkIcon} alt="check" />
                      <p className="text-black text-xs">
                        {t("price.priceOption-2-3")}{" "}
                      </p>
                    </div>
                    <div className="flex gap-2 items-center">
                      <img src={checkIcon} alt="check" />
                      <p className="text-black text-xs">
                        {t("price.priceOption-2-4")}{" "}
                      </p>
                    </div>
                    <div className="flex gap-2 items-center">
                      <img src={checkIcon} alt="check" />
                      <p className="text-black text-xs">
                        {t("price.priceOption-2-5")}{" "}
                      </p>
                    </div>
                    <div className="flex gap-2 items-center">
                      <img src={checkIcon} alt="check" />
                      <p className="text-black text-xs">
                        {t("price.priceOption-2-6")}{" "}
                      </p>
                    </div>
                    <div className="flex gap-2 items-center">
                      <img src={checkIcon} alt="check" />
                      <p className="text-black text-xs">
                        {t("price.priceOption-2-7")}{" "}
                      </p>
                    </div>
                    <div className="flex gap-2 items-center">
                      <img src={checkIcon} alt="check" />
                      <p className="text-black text-xs">
                        {t("price.priceOption-2-8")}{" "}
                      </p>
                    </div>
                    <div className="flex gap-2 items-center">
                      <img src={checkIcon} alt="check" />
                      <p className="text-black text-xs">
                        {t("price.priceOption-2-9")}{" "}
                      </p>
                    </div>
                  </div>
                  <button className="button-small button-primary mx-auto">
                    {t("price.button")}
                  </button>
                </div>
              </a>
              <a
                href="https://my.tfcapital.me/login"
                rel="noreferrer"
                className="lg:w-[400px] w-full border border-[#3E6BE4] p-2.5 bg-[#F4F7FF]"
              >
                <div className="forex-card relative lg:p-[52px] py-[52px] px-5 flex flex-col items-start gap-12">
                  <div className="flex flex-col gap-6">
                    <label className="text-[21px] leading-[29px] font-medium text-black">
                      {t("price.priceTitle-3")}
                    </label>
                    <div className="flex gap-1 items-end">
                      <p className="text-black text-[30px] font-bold leading-[100%]">
                        €5.000
                      </p>
                      <span className="text-sm leading-[20px] text-[#788497]">
                        {t("price.priceDesc")}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4">
                    <div className="flex gap-2 items-center">
                      <img src={checkIcon} alt="check" />
                      <p className="text-black text-xs">
                        {t("price.priceOption-3-1")}{" "}
                      </p>
                    </div>
                    <div className="flex gap-2 items-center">
                      <img src={checkIcon} alt="check" />
                      <p className="text-black text-xs">
                        {t("price.priceOption-3-2")}{" "}
                      </p>
                    </div>
                    <div className="flex gap-2 items-center">
                      <img src={checkIcon} alt="check" />
                      <p className="text-black text-xs">
                        {t("price.priceOption-3-3")}{" "}
                      </p>
                    </div>
                    <div className="flex gap-2 items-center">
                      <img src={checkIcon} alt="check" />
                      <p className="text-black text-xs">
                        {t("price.priceOption-3-4")}{" "}
                      </p>
                    </div>
                    <div className="flex gap-2 items-center">
                      <img src={checkIcon} alt="check" />
                      <p className="text-black text-xs">
                        {t("price.priceOption-3-5")}{" "}
                      </p>
                    </div>
                    <div className="flex gap-2 items-center">
                      <img src={checkIcon} alt="check" />
                      <p className="text-black text-xs">
                        {t("price.priceOption-3-6")}{" "}
                      </p>
                    </div>
                    <div className="flex gap-2 items-center">
                      <img src={checkIcon} alt="check" />
                      <p className="text-black text-xs">
                        {t("price.priceOption-3-7")}{" "}
                      </p>
                    </div>
                    <div className="flex gap-2 items-center">
                      <img src={checkIcon} alt="check" />
                      <p className="text-black text-xs">
                        {t("price.priceOption-3-8")}{" "}
                      </p>
                    </div>
                    <div className="flex gap-2 items-center">
                      <img src={checkIcon} alt="check" />
                      <p className="text-black text-xs">
                        {t("price.priceOption-3-9")}{" "}
                      </p>
                    </div>
                  </div>
                  <button className="button-small button-primary mx-auto">
                    {t("price.button")}
                  </button>
                </div>
              </a>
            </div>
            <div className="text-center mx-auto flex flex-col gap-1 text-[#788497] text-[12px] leading-[140%] font-bold">
              <p> {t("price.text-1")}</p>
              <p>{t("price.text-2")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForexCards;
