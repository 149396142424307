import googleBtn from "../../assets/googleBtn.svg";
import iosBtn from "../../assets/iosBtn.svg";
import vectorImg from "../../assets/usa-graph.png";
import vectorImgMobile from "../../assets/usa-graph-mobile.png";
import iphonesImg from "../../assets/usa-stock-hero.png";
import metaLogo from "../../assets/meta_logo.svg";
import googleLogo from "../../assets/google_logo.svg";
import appleLogo from "../../assets/apple_logo.svg";
import teslaLogo from "../../assets/tesla_logo.svg";
import microsoftLogo from "../../assets/microsoft_logo.svg";
import amazonLogo from "../../assets/amazon_logo.svg";
import nvidiaLogo from "../../assets/nvidia_logo.svg";
import InfiniteLooper from "../InfiniteLooper/InfiniteLooper";
import { useTranslation } from "react-i18next";

function UsaHero() {
	const { t } = useTranslation("usaBerza");

	return (
		<div className="scan-store-bg flex flex-col lg:gap-[60px] gap-[40px] relative">
			<div className="usa-stock-graph w-full 2xl:w-[80%] absolute bottom-0 right-0 z-10 lg:block hidden">
				<img src={vectorImg} alt="vector" className="w-full" />
			</div>
			<div className="w-full relative z-20">
				<div className="lg:pt-[80px] pt-[48px] relative z-20 mx-auto container px-[16px] lg:px-[20px] lg:flex-row flex-col flex gap-12 items-center justify-between">
					<div className="max-w-[598px] flex flex-col gap-9">
						<h2 className="lg:text-[55px] text-[36px] leading-[130%] text-white fond-medium">
							{t("usaHero.title")}{" "}
							<span className="text-[#C8E64C] font-bold">
								{t("usaHero.titlePartTwo")}
							</span>
						</h2>
						<p className="lg:text-[16px] text-[14px] text-white leading-[150%]">
							{t("usaHero.textPartOne")}{" "}
							<span className="font-bold">{t("usaHero.textPartTwo")} </span>
							{t("usaHero.textPartThree")}
						</p>
						<div className="flex items-center gap-6">
							<a
								href="https://apps.apple.com/me/app/tf-trader-investment-ally/id6474622913"
								target="_blank"
								rel="noreferrer"
							>
								<img
									src={iosBtn}
									alt="apple-btn"
									className="w-[128px] h-[40px]"
								/>
							</a>
							<a
								href="https://play.google.com/store/apps/details?id=com.incoditech.tfcapital&pcampaignid=web_share"
								target="_blank"
								rel="noreferrer"
							>
								<img
									src={googleBtn}
									alt="apple-btn"
									className="w-[128px] h-[40px]"
								/>
							</a>
						</div>
					</div>
					<div className="relative">
						<img
							src={iphonesImg}
							alt="iphones"
							className="lg:max-w-[493px] w-full relative z-20"
						/>
					</div>
				</div>
				<img
					src={vectorImgMobile}
					alt="graph-mobile"
					className="absolute bottom-0 left-0 lg:hidden block w-full z-10"
				/>
			</div>
			<div className="relative z-20 container mx-auto px-[16px] lg:px-[20px] lg:pb-[60px] pb-[48px]">
				<InfiniteLooper speed="24" direction="left">
					<a href="https://about.meta.com/" target="_blank" rel="noreferrer">
						<img
							src={metaLogo}
							alt="meta_logo"
							className="mr-[50px] lg:mr-[105px] transform lg:scale-75"
						/>
					</a>
					<a href="https://www.google.com/" target="_blank" rel="noreferrer">
						<img
							src={googleLogo}
							alt="google_logo"
							className="mr-[50px] lg:mr-[105px] transform lg:scale-75"
						/>
					</a>
					<a href="https://www.apple.com" target="_blank" rel="noreferrer">
						<img
							src={appleLogo}
							alt="apple_logo"
							className="mr-[50px] lg:mr-[105px] transform lg:scale-75"
						/>
					</a>
					<a href="https://www.tesla.com/" target="_blank" rel="noreferrer">
						<img
							src={teslaLogo}
							alt="tesla_logo"
							className="mr-[50px] lg:mr-[105px] transform lg:scale-75"
						/>
					</a>
					<a href="https://www.amazon.com/" target="_blank" rel="noreferrer">
						<img
							src={microsoftLogo}
							alt="microsoft_logo"
							className="mr-[50px] lg:mr-[105px] transform lg:scale-75"
						/>
					</a>
					<a href="https://www.amazon.com/" target="_blank" rel="noreferrer">
						<img
							src={amazonLogo}
							alt="amazon_logo"
							className="mr-[50px] lg:mr-[105px] transform lg:scale-75"
						/>
					</a>
					<a href="https://www.nvidia.com/" target="_blank" rel="noreferrer">
						<img
							src={nvidiaLogo}
							alt="nvidia_logo"
							className="mr-[50px] lg:mr-[105px] transform lg:scale-75"
						/>
					</a>
				</InfiniteLooper>
			</div>
		</div>
	);
}

export default UsaHero;
