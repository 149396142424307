import { httpClient } from "../httpClient";

export const getNews = async () => {
  const response = await httpClient.get("/posts?category=news");
  console.log(response);
  return response;
};

export const getBlog = async (id) => {
  const response = await httpClient.get(`/posts/${id}`);
  return response;
};

export const getSpecificNews = async (tag, limit) => {
  if (tag === 'all') {
    const response = await httpClient.get(
      `/posts?category=news`
    );
    return response;
  } else {
    const response = await httpClient.get(
      `/posts?category=news&tags=${tag}`
    );
    return response;
  }
};

export const BlogKeys = {
  NEWS: "NEWS",
  BLOG: "BLOG",
};
