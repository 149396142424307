// src/i18n.js

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import homeMe from "./me/home.json";
import homeEn from "./en/home.json";
import headerMe from "./me/header.json";
import headerEn from "./en/header.json";
import aboutUsMe from "./me/aboutUs.json";
import aboutUsEn from "./en/aboutUs.json";
import contactMe from "./me/contact.json";
import contactEn from "./en/contact.json";
import capitalProductsMe from "./me/capitalProducts.json";
import capitalProductsEn from "./en/capitalProducts.json";
import faqMe from "./me/faq.json";
import faqEn from "./en/faq.json";
import whyTfMe from "./me/whyTf.json";
import whyTfEn from "./en/whyTf.json";
import footerMe from "./me/footer.json";
import footerEn from "./en/footer.json";
import scanMe from "./me/scan.json";
import scanEn from "./en/scan.json";
import newsMe from "./me/news.json";
import newEn from "./en/news.json";
import forexEn from "./en/forex.json";
import forexMe from "./me/forex.json";
import montenegroberzeMe from "./me/montenegroberze.json";
import montenegroberzeEn from "./en/montenegroberze.json";
import usaBerzaMe from "./me/usaBerza.json";
import usaBerzaEn from "./en/usaBerza.json";
import forexPageMe from "./me/forexPage.json";
import forexPageEn from "./en/forexPage.json";
import montenegroberzePageMe from "./me/montenegroberzePage.json";
import montenegroberzePageEn from "./en/montenegroberzePage.json";
import privacyPolicyMe from "../locales/me/privacyPolicy.json";
import privacyPolicyEn from "../locales/en/privacyPolicy.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      home: homeEn,
      header: headerEn,
      aboutUs: aboutUsEn,
      contact: contactEn,
      capitalProducts: capitalProductsEn,
      faq: faqEn,
      whyTf: whyTfEn,
      footer: footerEn,
      scan: scanEn,
      news: newEn,
      forex: forexEn,
      montenegroberze: montenegroberzeEn,
      usaBerza: usaBerzaEn,
      forexPage: forexPageEn,
      montenegroberzePage: montenegroberzePageEn,
      privacyPolicyPage: privacyPolicyEn
    },
    me: {
      home: homeMe,
      header: headerMe,
      aboutUs: aboutUsMe,
      contact: contactMe,
      capitalProducts: capitalProductsMe,
      faq: faqMe,
      whyTf: whyTfMe,
      footer: footerMe,
      scan: scanMe,
      news: newsMe,
      forex: forexMe,
      montenegroberze: montenegroberzeMe,
      usaBerza: usaBerzaMe,
      forexPage: forexPageMe,
      montenegroberzePage: montenegroberzePageMe,
      privacyPolicyPage: privacyPolicyMe
    },
  },
  lng: "me",
  fallbackLng: "me",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
