import * as Yup from "yup";
import { useState, useCallback, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import { useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";
import MontenegroSVG from "../../assets/Montenegro.svg";
import IconSVG from "../../assets/icon.svg";
import checkSuccess from "../../assets/check-success.svg";
import checkError from "../../assets/check-error.svg";

import TitleSection from "../TitleSection/TitleSection";
import { useMutation } from "@tanstack/react-query";
import { submitForm } from "../../api/repositories/contactRepository";

function ContactSectionNew(showTitle = false) {
  const { t } = useTranslation("contact");
  const [token, setToken] = useState("");
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const location = useLocation();
  const siteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("contactValidation.name")),
    surname: Yup.string().required(t("contactValidation.name")),
    email: Yup.string()
      .email(t("contactValidation.email"))
      .required(t("contactValidation.emailRequired")),
    number: Yup.number().required(t("contactValidation.name")),
    message: Yup.string().required(t("contactValidation.message")),
  });

  const initialValues = {
    name: "",
    surname: "",
    email: "",
    number: "",
    message: "",
  };

  const { mutate, status } = useMutation({
    mutationFn: submitForm,
    onSuccess: () => {
      console.log("Uspjesno poslato");
    },
    onError: (error) => {
      throw new Error('Something went wrong!');
    },
  });

  const verifyRecaptchaCallback = useCallback((token) => {
    setToken(token);
  }, []);

  useEffect(() => {
    const executeRecaptcha = () => {
      if (window.grecaptcha) {
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(siteKey, { action: 'submit' })
            .then(captcha => {
              verifyRecaptchaCallback(captcha);
            });
        });
      } else {
        // Wait for reCAPTCHA to be defined
        setTimeout(executeRecaptcha, 100);
      }
    };

    executeRecaptcha();
  }, [siteKey, verifyRecaptchaCallback, refreshReCaptcha]);

  const handleSubmit = (values, { resetForm }) => {
    const payload = {
      first_name: values.name,
      last_name: values.surname,
      email: values.email,
      phone: values.number,
      message: values.message,
      page_origin: location.pathname.slice(1),
      captchaToken: token
    };
    mutate(payload);
    resetForm();
  };

  useEffect(() => {
    setTimeout(() => {
      setRefreshReCaptcha(r => !r);
    }, 120000);
  }, [token]);

  return (
    <div className="flex flex-col items-center gap-[60px] pt-[50px] pb-[75px]">
      <div className="px-[60px]">
        <TitleSection title={t("contactForm.title")} />
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, setFieldTouched, values, errors, touched }) => (
          <Form className="flex flex-col items-center gap-8 lg:w-[906px] md:w-[500px] w-full">
            <div className="flex justify-between lg:flex-row flex-col gap-5 items-center w-full">
              <div className="flex flex-wrap justify-center lg:flex-col  flex-col gap-6 lg:w-[390px] w-full">
                <div className="w-full flex justify-center lg:flex-row flex-col  gap-[21px]">
                  <div className="flex flex-col gap-[10px]">
                    <label className="text-[10px] text-[#788497] font-bold leading-[12px]">
                      {t("contactForm.nameLabel")}
                    </label>
                    <div className="relative">
                      <Field
                        name="name"
                        id="name"
                        className={` lg:w-[184px] w-full h-[40px] bg-[#F4F7FF] text-[12px] text-[#A6A6A6] leading-[14px] pl-3 `}
                        type="text"
                      />
                      <div>
                        <img
                          className={`absolute top-[13px] right-[13px] rounded-[10px]`}
                          src={
                            touched.name
                              ? errors.name
                                ? checkError
                                : checkSuccess
                              : IconSVG
                          }
                          alt="Icon"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-[10px]">
                    <label className="text-[10px] text-[#788497] font-bold leading-[12px]">
                      {t("contactForm.surnameLabel")}
                    </label>
                    <div className="relative">
                      <Field
                        name="surname"
                        id="surname"
                        className="lg:w-[184px] w-full h-[40px] bg-[#F4F7FF] text-[12px] text-[#A6A6A6] leading-[14px] pl-3"
                        type="text"
                      />
                      <img
                        className={`absolute top-[13px] right-[13px] rounded-[10px]`}
                        src={
                          touched.surname
                            ? errors.surname
                              ? checkError
                              : checkSuccess
                            : IconSVG
                        }
                        alt="Icon"
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full flex flex-col gap-2">
                  <label className="text-[10px] text-[#788497] font-bold leading-[12px]">
                    {t("contactForm.emailLabel")}
                  </label>
                  <div className="relative w-full">
                    <Field
                      name="email"
                      id="email"
                      className="w-full h-[40px] bg-[#F4F7FF] text-[12px] text-[#A6A6A6] leading-[14px] pl-3"
                      type="email"
                    />
                    <img
                      className={`absolute top-[13px] right-[13px] rounded-[10px] `}
                      src={
                        touched.email
                          ? errors.email
                            ? checkError
                            : checkSuccess
                          : IconSVG
                      }
                      alt="Icon"
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-[10px]">
                  <label className="text-[10px] text-[#788497] font-bold leading-[12px]">
                    {t("contactForm.number")}
                  </label>
                  <div className="flex w-full">
                    <div className="bg-[#F4F7FF] mr-1">
                      <img
                        className="px-[12px] py-[10px]"
                        src={MontenegroSVG}
                        alt="Montenegro"
                      />
                    </div>
                    <div className="relative w-full">
                      <span className="px-3 py-[13.5px] text-[12px] text-[#CCD0D7] leading-[14px] absolute ">
                        +382
                      </span>
                      <Field
                        name="number"
                        id="number"
                        className="w-full h-[40px] bg-[#F4F7FF] text-[12px] text-[#A6A6A6] leading-[14px] pl-[55px]"
                        type="text"
                      />
                      <img
                        className={`absolute top-[13px] right-[13px] rounded-[10px] `}
                        src={
                          touched.number
                            ? errors.number
                              ? checkError
                              : checkSuccess
                            : IconSVG
                        }
                        alt="Icon"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="2xl:w-[596px] lg:w-[546px]  w-full h-[231px] gap-[10px] flex flex-col ">
                <label className="text-[10px] text-[#788497] font-bold leading-[12px]">
                  {t("contactForm.question")}
                </label>
                <div className="relative w-full h-full ">
                  <Field
                    as="textarea"
                    id="message"
                    name="message"
                    className=" w-full h-full bg-[#F4F7FF] text-[12px] text-[#A6A6A6] leading-[19px] p-3"
                  ></Field>
                  <img
                    className={`absolute bottom-[13px] right-[13px] rounded-[10px]`}
                    src={
                      touched.message
                        ? errors.message
                          ? checkError
                          : checkSuccess
                        : IconSVG
                    }
                    alt="Icon"
                  />
                </div>
              </div>
            </div>
            <button
              disabled={status === "pending"}
              type="submit"
              className="button-primary button-big"
            >
              {status === "pending" ? (
                <div className="border-t-transparent mx-auto border-solid animate-spin rounded-full border-blue-500 border-2 h-3 w-3"></div>
              ) : (
                t("contactForm.buttonText")
              )}
            </button>
            {(status === "success" || status === "error") && (
              <p
                className={`text-[14px] leading-[19px] ${status === "success" ? "text-green-500" : "text-red-500"
                  }`}
              >
                {status === "success" && t("contactForm.successMsg")}
                {status === "error" && t("contactForm.errorMsg")}
              </p>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ContactSectionNew;
