import NewsHero from "../components/NewsHero/NewsHero";
import CapitalProducts from "../components/CapitalProducts/CapitalProducts";
import ForexCFDSmall from "../components/ForexCFDSmall/ForexCFDSmall";
import Scan from "../components/Scan/Scan";
import NewsReUse from "../components/NewsReUse/NewsReUse";
import { useTranslation } from "react-i18next";
import { BlogKeys, getSpecificNews } from "../api/repositories/blogRepository";
import { useQuery } from "@tanstack/react-query";
import ContentLoader from "react-content-loader";
import { useEffect, useState } from "react";

function News() {
  const { t } = useTranslation("news");
  const [tag, setTag] = useState('all');

  const { data: usaNews, isLoading: isUsaLoading, error } = useQuery({
    queryKey: ['NEWS', tag], // Query key
    queryFn: () => getSpecificNews(tag, 1), // Query function
    enabled: tag !== '', // Only run the query if a tag is selected
  });

  return (
    <div className="relative z-20" >
      <div
        id="us-stock"
        className="container mx-auto lg:px-[20px] px-[16px] mt-[-35px]"
      >
        {isUsaLoading || tag === "" ? (
          <div className="container mx-auto px-[16px] lg:px-[20px] pt-8">
            <ContentLoader />
          </div>
        ) : (usaNews.data &&
          usaNews.data.length > 0 &&
          <NewsHero
            title={tag}
            navLink="/"
            navTitle={t("newsHero.navLinkTitle")}
            items={usaNews.data}
            setTag={setTag}
            tag={tag}
          />)
        }
      </div>
    </div >
  );
}

export default News;
