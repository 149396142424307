function CookiePolicy() {
  return (
    <div className="container mx-auto lg:px-[20px] px-[16px] lg:py-[80px] py-[60px]">
      <h1 className="font-bold text-[48px] mb-[50px] text-black text-center">
        Cookie Policy
      </h1>
      <div className="mx-auto max-w-[916px] text-[#585768] text-[16px] leading-[28px] mb-[40px]">
        Statistical Information and Cookie TF Capital Markets is using cookies
        in order to collect statistical information about visitors to our
        websites. Collected data are: the number of visitors, pages viewed,
        types of transactions conducted, time spent online, documents
        downloaded, the name of your Internet service provider, the IP address,
        the date and time of access, the difference in the time zone relative to
        the GMT, the access status / HTTP status for the amount of downloaded
        data, the browser, the language, the browser version, the operating
        system and the screen surface, the website linked you to our site, the
        web pages you have visited from our site. This information is used to
        evaluate and improve the performance of our websites. Other than
        statistical information, we do not collect any information about you
        through our website unless you provide the information to us. TF Capital
        Markets may share website usage statistics with reputable advertising
        companies and with its marketing companies. The information collected by
        the advertising company is not personally identifiable.
      </div>

    </div>
  );
}

export default CookiePolicy;
