import numbersContainerImg from "../../assets/animate-numders.png";
import telekomLogo from "../../assets/telekom_logo.svg";
import jugopetrolLogo from "../../assets/jugopetrol_logo.svg";
import lukaBarLogo from "../../assets/lukaBar_logo.svg";
import moracaLogo from "../../assets/moraca_logo.svg";
import epcgLogo from "../../assets/epcgLogo.svg";
import cgscLogo from "../../assets/cgcsLogo.svg";

import InfiniteLooper from "../InfiniteLooper/InfiniteLooper";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function MontenegroStock({ isMontenegroPage = false }) {
  const navigate = useNavigate();
  const { t } = useTranslation("home");

  return (
    <div className=" montenegro-stock-bg w-full lg:pt-[60px] lg:pb-[41px] py-[48px] relative">
      <div className="container lg:px-[20px] px-[16px] mx-auto flex flex-col">
        <div className="relative z-20 flex justify-between  flex-wrap items-center">
          <div className="flex flex-col gap-[32px] max-w-[447px]">
            <div className="flex flex-col gap-[32px]">
              <div className="flex flex-col gap-[32px]">
                {!isMontenegroPage && (
                  <span className="font-bold lg:text-[16px] text-[18px] leading-[12px] text-[#CCD0D7]">
                    {t("montenegroStock.subtitle")}
                  </span>
                )}
                <div
                  className={`${
                    isMontenegroPage
                      ? "lg:text-[55px] leading-[130%] text-[48px]"
                      : "leading-[35px] text-[30px]"
                  } text-[#FFF]`}
                >
                  <h4 className="font-medium text-[#FFF]">
                    {t("montenegroStock.titleOne")}
                  </h4>
                  <h4 className="font-bold text-[#C8E64C]">
                    {t("montenegroStock.titleTwo")}
                  </h4>
                  <h4 className="font-medium text-[#FFF]">
                    {t("montenegroStock.titleThree")}
                  </h4>
                </div>
              </div>
              <div
                className={`flex flex-col text-white ${
                  isMontenegroPage
                    ? "lg:text-[16px] text-[14px] leading-[24px]"
                    : "lg:text-[12px] text-[14px] leading-[19.5px]"
                }`}
              >
                <p
                  dangerouslySetInnerHTML={{
                    __html: !isMontenegroPage
                      ? t("montenegroStock.textOne")
                      : t("montenegroStock.montenegroTextOne"),
                  }}
                ></p>
              </div>
            </div>
            {!isMontenegroPage && (
              <div className="lg:mt-0 flex items-center flex-row gap-[20px]">
                <button
                  onClick={() => navigate("/montenegroberze#montenegro-form")}
                  className="button-primary button-big"
                >
                  {t("montenegroStock.registerButton")}
                </button>
                <button
                  onClick={() => navigate("/contact#contact")}
                  className="button-secondary button-big"
                >
                  {t("scheduleConsulting")}
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col lg:flex-row justify-end xl:w-full lg:w-[430px] w-full xl:h-full lg:h-[405px] h-full z-10 lg:absolute relative lg:top-[40px] top-0 right-0 my-[48px] lg:my-0 ">
          <img src={numbersContainerImg} alt="qr-code" />
        </div>
        <div className="lg:pt-[100px] pt-0 relative z-20">
          <InfiniteLooper speed="24" direction="left">
            <a href="https://telekom.me/" target="_blank" rel="noreferrer">
              <img
                src={telekomLogo}
                alt="telekom_logo"
                className="mr-[50px] lg:mr-[150px]  transform scale-75"
              />
            </a>
            <a
              href="https://www.jugopetrol.co.me/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={jugopetrolLogo}
                alt="jugopetrol_logo"
                className="mr-[50px] lg:mr-[150px] transform scale-75"
              />
            </a>
            <a href="https://www.epcg.com/" target="_blank" rel="noreferrer">
              <img
                src={epcgLogo}
                alt="epcg_logo"
                className="mr-[50px] lg:mr-[150px] transform scale-75"
              />
            </a>
            <a href="https://lukabar.me/" target="_blank" rel="noreferrer">
              <img
                src={lukaBarLogo}
                alt="lukaBar_logo"
                className="mr-[50px] lg:mr-[150px] transform scale-75"
              />
            </a>
            <a href="https://www.cges.me/" target="_blank" rel="noreferrer">
              <img
                src={cgscLogo}
                alt="cgsc_logo"
                className="mr-[50px] lg:mr-[150px] transform scale-75"
              />
            </a>
            <img
              src={moracaLogo}
              alt="moraca_logo"
              className="mr-[50px] lg:mr-[150px] transform scale-75"
            />
          </InfiniteLooper>
        </div>
      </div>
    </div>
  );
}

export default MontenegroStock;
