import searchIcon from "../../assets/search_icon.svg";

function EducationSearch() {
  return (
    <div className="w-full py-[29px] search-bg ">
      <div className="container mx-auto lg:px-[20px] px-[16px] flex items-center justify-center">
        <div className="relative w-[680px]">
          <input
            type="text"
            className="h-[54px] focus:outline-none pl-[66px] py-[18px] w-full text-[#788497] text-[18px] leading-[18px]"
            placeholder="Pretraži edukativne sadržaje"
          />
          <img
            src={searchIcon}
            alt="search"
            className="absolute left-[24px] top-[12px]"
          />
        </div>
        <button className="h-[54px] text-center bg-[#3E68E4] px-[40px] py-[18px] text-[18px] leading-[18px] text-white">
          Pretraži
        </button>
      </div>
    </div>
  );
}

export default EducationSearch;
