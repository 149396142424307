import NewsHeroCard from "../NewsHeroCard/NewsHeroCard";
import arrowTrIcon from "../../assets/arrow-tr.svg";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useSearchParams } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

function NewsHero({ title, navLink, navTitle, items, setTag, tag }) {
  const locale = useTranslation("home").i18n.language;
  const { t } = useTranslation("news");
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);


  function transformSentence(sentence) {
    let lowercaseSentence = sentence.toLowerCase();
    let words = lowercaseSentence.split(' ');
    let capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    let transformedSentence = capitalizedWords.join(' ');

    return transformedSentence;
  }

  const getNewsLabel = (label) => {
    switch (label) {
      case "all":
        return locale === "me" ? "Sve" : "All";
      case "usa berze":
        return locale === "me" ? "USA berze" : "USA stock";
      case "forex":
        return locale === "me" ? "Forex" : "Forex";
      case "Other":
        return locale === "me" ? "Ostalo" : "Other";
      default:
        return locale === "me" ? "Ostalo" : "Other";
    }
  };

  const layoutType = (number) => {
    return number > 2 ? "start" : "between";
  };

  function getFilterValue(queryString) {
    const params = new URLSearchParams(queryString);
    const filterValue = params.get('filter');
    return filterValue ? decodeURIComponent(filterValue.replace(/\+/g, ' ')) : null;
  }

  const handleTagChange = (newTag) => {
    setTag(newTag);
    searchParams.set('filter', newTag);
    setSearchParams(searchParams, { replace: true });
  };



  // Initial load based on URL parameters
  useEffect(() => {
    if (!initialized) {
      const paramsFilterValue = getFilterValue(location.search);
      setTag(paramsFilterValue === "" ? "all" : paramsFilterValue);
      setInitialized(true);
    }
  }, [location.search, initialized, setTag]);

  // Sync state changes with URL parameters
  useEffect(() => {
    if (initialized) {
      searchParams.set('filter', tag);
      setSearchParams(searchParams, { replace: true });
    }
  }, [tag, initialized, searchParams, setSearchParams]);



  return (
    <div className="lg:py-[75px] py-[50px]">
      <p className="text-[#788497] text-[16px] leading-[30px] mb-[16px]">
        <NavLink className="hover-color-dark" to="/">TF Capital {" > "}</NavLink>
        <NavLink className="hover-color-dark" to="/news?filter=all">{locale === "me" ? "Investiranje > " : "Investing > "}</NavLink><span className="font-medium">{getNewsLabel(title)}</span>
      </p>
      <div className="flex justify-center mt-[68px] mb-[100px]">
        <div className="transform -skew-x-12" onClick={() => handleTagChange("all")}>
          <div className={`${tag === "all" ? 'bg-[#C8E64C]' : 'bg-[#F4F7FF]'} duration-200 transition ease-in-out px-2 py-2 w-[80px] h-[36px] flex justify-center items-center lg:hover:bg-opacity-30 lg:hover:bg-[#C8E64C] cursor-pointer`}>
            <span className="transform skew-x-12">{locale === 'me' ? "Sve" : "All"}</span>
          </div>
        </div>

        <div className="transform -skew-x-12" onClick={() => handleTagChange("usa berze")}>
          <div className={`${tag === "usa berze" ? 'bg-[#C8E64C]' : 'bg-[#F4F7FF]'} duration-200 transition ease-in-out px-2 py-2 w-[111px] h-[36px] flex justify-center items-center lg:hover:bg-opacity-30 lg:hover:bg-[#C8E64C] cursor-pointer`}>
            <span className="transform skew-x-12">{locale === 'me' ? "USA berze" : "USA stocks"}</span>
          </div>
        </div>

        <div className="transform -skew-x-12" onClick={() => handleTagChange("forex")}>
          <div className={`${tag === "forex" ? 'bg-[#C8E64C]' : 'bg-[#F4F7FF]'} duration-200 transition ease-in-out px-2 py-2 w-[80px] h-[36px] flex justify-center items-center lg:hover:bg-opacity-30 lg:hover:bg-[#C8E64C] cursor-pointer`}>
            <span className="transform skew-x-12">Forex</span>
          </div>
        </div>

        <div className="transform -skew-x-12" onClick={() => handleTagChange("other")}>
          <div className={`${tag === "other" ? 'bg-[#C8E64C]' : 'bg-[#F4F7FF]'} duration-200 transition ease-in-out px-2 py-2 w-[80px] h-[36px] flex justify-center items-center lg:hover:bg-opacity-30 lg:hover:bg-[#C8E64C] cursor-pointer`}>
            <span className="transform skew-x-12">{locale === 'me' ? "Ostalo" : "Other"}</span>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:gap-[125px] gap-[36px] items-center justify-start">
        <div className={`w-full lg:gap-y-[88px] gap-y-[50px] flex items-center justify-center sm:items-start flex-row flex-wrap ${layoutType(items.length) ? "lg:justify-center" : "lg:justify-between"} gap-[36px]`}>
          {items && items.map((item, index) => {
            const subcategory = transformSentence(item.subcategory);
            const tags = item?.tags.split(",");
            return (
              <div key={index}>
                <NewsHeroCard
                  title={item.title[locale]}
                  short_description={item.short_description[locale]}
                  category={getNewsLabel(subcategory)}
                  tags={tags}
                  id={item.id}
                  coverImg={item.full_photo_path || ""}
                />
              </div>
            );
          })}
          {/* <div className="cursor-pointer flex flex-row items-center gap-[12px] justify-center lg:justify-start">
            <p className="underline text-[#3E6BE4] text-[14px] leading-[30px] font-semibold">
              {t("newsHero.navLinkTitle")}
            </p>
            <img src={arrowTrIcon} alt="arrow" />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default NewsHero;
