import metaIcon from "../assets/meta-gray-icon.svg";
import googleIcon from "../assets/google-gray-logo.svg";
import appleIcon from "../assets/apple-gray-logo.svg";
import teslaIcon from "../assets/tesla-gray-logo.svg";
import microsoftIcon from "../assets/microsoft-gray-logo.svg";
import nvidiaIcon from "../assets/nvidia-gray-logo.svg";
import amazonIcon from "../assets/amazon-gray-logo.svg";
import checkIcon from "../assets/check-styled-icon.svg";
import InfiniteLooper from "../components/InfiniteLooper/InfiniteLooper";
import Scan from "../components/Scan/Scan";
import { useTranslation } from "react-i18next";
import QuestionTabs from "../components/QuestionTabs/QuestionTabs";
import Newsletter from "../components/Newsletter/Newsletter";
import NewsReUse from "../components/NewsReUse/NewsReUse";
import CapitalProducts from "../components/CapitalProducts/CapitalProducts";
import ContactSectionNew from "../components/ContactSectionNew/ContactSectionNew";
import { useState, useEffect, useRef } from "react";
import { motion, useViewportScroll, AnimatePresence } from "framer-motion";
import TitleSection from "../components/TitleSection/TitleSection";
import UsaHero from "../components/UsaHero/UsaHero";
import stepOne from "../assets/usa-step-1.png";
import stepTwo from "../assets/usa-step-2.png";
import stepThree from "../assets/usa-step-3.png";
import stepFour from "../assets/usa-step-4.png";
import iosBtn from "../assets/iosBtn.svg";
import googleBtn from "../assets/googleBtn.svg";
import { BlogKeys, getSpecificNews } from "../api/repositories/blogRepository";
import { useQuery } from "@tanstack/react-query";
import ContentLoader from "react-content-loader";

function Usaberza() {
  const { t } = useTranslation("usaBerza");

  const { data: news, isLoading } = useQuery({
    queryKey: [BlogKeys.NEWS + "-usa"],
    queryFn: () => getSpecificNews("USA berze", 8),
  });

  const data = [
    {
      question: t("faq.q1.question"),
      answer: t("faq.q1.answer"),
    },
    {
      question: t("faq.q2.question"),
      answer: t("faq.q2.answer"),
    },
    {
      question: t("faq.q3.question"),
      answer: t("faq.q3.answer"),
    },
    {
      question: t("faq.q4.question"),
      answer: t("faq.q4.answer"),
    },
    {
      question: t("faq.q5.question"),
      answer: t("faq.q5.answer"),
    },
  ];

  const textVariants = {
    hidden: {
      opacity: 0,
      y: 30,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    },
    exit: {
      opacity: 0,
      y: -30,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    },
  };
  const [activeSection, setActiveSection] = useState(1);
  const { scrollY } = useViewportScroll();
  const scrollSection = useRef(null);

  useEffect(() => {
    var circle = document.getElementById("animatedCircle");
    var totalLength = 163.36; // Ukupna dužina granice kruga s radijusom 26 piksela
    var offset = totalLength - activeSection * (totalLength / 4);
    circle.style.strokeDashoffset = offset;
  }, [activeSection]);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollSection.current) {
        const rect = scrollSection.current.getBoundingClientRect();
        const offsetTop = rect.top + window.scrollY;

        const scrollYValue = scrollY.get();

        if (scrollYValue < offsetTop) {
          setActiveSection(1);
        } else if (
          scrollYValue >= offsetTop &&
          scrollYValue < offsetTop + 490
        ) {
          setActiveSection(2);
        } else if (
          scrollYValue >= offsetTop &&
          scrollYValue < offsetTop + 490 + 490
        ) {
          setActiveSection(3);
        } else {
          setActiveSection(4);
        }
      }
    };

    const unsubscribeY = scrollY.onChange(handleScroll);

    return () => {
      unsubscribeY();
    };
  }, [scrollY]);

  const getCircleColor = (circleNumber) =>
    activeSection >= circleNumber ? "bg-[#3E6BE4]" : "bg-[#CCD0D7]";

  const getTextColor = (circleNumber) =>
    activeSection >= circleNumber ? "text-[#3E6BE4]" : "text-[#CCD0D7]";

  return (
    <>
      <UsaHero />
      <div className="flex xl:flex-row flex-col xl:pt-[120px] xl:pb-[80px] py-[64px] gap-[72px] xl:gap-[105px] xl:items-start items-center justify-center container mx-auto px-[16px] lg:px-[20px]">
        <div className="sm:w-[568px] w-full flex flex-col gap-12">
          <div
            style={{
              background:
                "linear-gradient(270deg, #C8E64C -7.18%, rgba(200, 230, 76, 0.00) 74.08%)",
            }}
            className="sm:w-[568px] w-full relative transform md:-skew-x-12 -skew-x-3"
          >
            <h2 className="py-2 max-w-[415px] font-medium text-[30px] leading-[140%]  transform md:skew-x-12 skew-x-3">
              {t("sectionOne.title")}
              <b> {t("sectionOne.titlePartTwo")}</b>
            </h2>
          </div>
          <p className="max-w-[525px] xl:pt-[24px] text-[14px] leading-[140%]">
            {t("sectionOne.text-1")} <b>{t("sectionOne.text-2")}</b>,
            {t("sectionOne.text-3")}
          </p>
          <div className="hidden max-w-[500px] md:flex flex-wrap items-center justify-center gap-[37px]">
            <img src={metaIcon} alt="meta-icon" />
            <img src={googleIcon} alt="google-icon" />
            <img src={appleIcon} alt="apple-icon" />
            <img src={teslaIcon} alt="tesla-icon" />
            <img src={microsoftIcon} alt="microsoft-icon" />
            <img src={nvidiaIcon} alt="nvidia-icon" />
            <img src={amazonIcon} alt="amazon-icon" />
          </div>
          <div className="md:hidden block">
            <InfiniteLooper speed="24" direction="left">
              <img className="mr-[50px]" src={metaIcon} alt="meta-icon" />
              <img className="mr-[50px]" src={googleIcon} alt="google-icon" />
              <img className="mr-[50px]" src={appleIcon} alt="apple-icon" />
              <img className="mr-[50px]" src={teslaIcon} alt="tesla-icon" />
              <img
                className="mr-[50px]"
                src={microsoftIcon}
                alt="microsoft-icon"
              />
              <img className="mr-[50px]" src={nvidiaIcon} alt="nvidia-icon" />
              <img className="mr-[50px]" src={amazonIcon} alt="amazon-icon" />
            </InfiniteLooper>
          </div>
        </div>
        <div className="sm:w-[568px] w-full flex flex-col xl:gap-[79px] gap-[48px]">
          <div
            style={{
              background:
                "linear-gradient(270deg, #467AFF -7.18%, rgba(70, 122, 255, 0.00) 74.08%)",
            }}
            className="sm:w-[568px] w-full relative transform md:-skew-x-12 -skew-x-3"
          >
            <h2 className="py-2 max-w-[456px] font-medium text-[30px] leading-[140%]  transform md:skew-x-12 skew-x-3">
              {t("sectionOne.titleTwo-1")} <b>{t("sectionOne.titleTwo-2")}</b>{" "}
              {t("sectionOne.titleTwo-3")}
            </h2>
          </div>
          <div className="flex flex-wrap xl:gap-[32px] gap-[25px] items-center justify-between">
            <div className="flex items-center gap-4 sm:flex-[43%] flex-[100%]">
              <img src={checkIcon} alt="check-icon" />
              <p className=" font-bold text-[14px] leading-[140%]">
                {t("sectionOne.benefit-1")}
              </p>
            </div>
            <div className="flex items-center gap-4 sm:flex-[43%] flex-[100%]">
              <img src={checkIcon} alt="check-icon" />
              <p className=" font-bold text-[14px] leading-[140%]">
                {t("sectionOne.benefit-2")}
              </p>
            </div>
            <div className="flex items-center gap-4 sm:flex-[43%] flex-[100%]">
              <img src={checkIcon} alt="check-icon" />
              <p className=" font-bold text-[14px] leading-[140%]">
                {t("sectionOne.benefit-3")}
              </p>
            </div>
            <div className="flex items-center gap-4 sm:flex-[43%] flex-[100%]">
              <img src={checkIcon} alt="check-icon" />
              <p className=" font-bold text-[14px] leading-[140%]">
                {t("sectionOne.benefit-4")}
              </p>
            </div>
            <div className="flex items-center gap-4 sm:flex-[43%] flex-[100%]">
              <img src={checkIcon} alt="check-icon" />
              <p className=" font-bold text-[14px] leading-[140%]">
                {t("sectionOne.benefit-5")}
              </p>
            </div>
            <div className="flex items-center gap-4 sm:flex-[43%] flex-[100%]">
              <img src={checkIcon} alt="check-icon" />
              <p className=" font-bold text-[14px] leading-[140%]">
                {t("sectionOne.benefit-6")}
              </p>
            </div>
            <div className="flex items-center gap-4 sm:flex-[43%] flex-[100%]">
              <img src={checkIcon} alt="check-icon" />
              <p className=" font-bold text-[14px] leading-[140%]">
                {t("sectionOne.benefit-7")}
              </p>
            </div>
            <div className="flex items-center gap-4 sm:flex-[43%] flex-[100%]">
              <img src={checkIcon} alt="check-icon" />
              <p className=" font-bold text-[14px] leading-[140%]">
                {t("sectionOne.benefit-8")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          background:
            "radial-gradient(23.55% 48.32% at 74.21% 66.8%, rgba(230, 248, 158, 0.70) 0%, #F4F7FF 100%)",
        }}
      >
        <div className="w-full ">
          <div className="container mx-auto px-[16px] lg:px-[20px] lg:pb-[350px] pb-[340px] pt-[64px] lg:pt-[80px]">
            <TitleSection
              title={t("sectionTwo.title")}
              subtitle={t("sectionTwo.subtitle")}
              subtitleColor="text-[#3E6BE4]"
            />
          </div>
        </div>

        <div ref={scrollSection} className="h-[2000px] relative w-full p-0 m-0">
          <div className="lg:block hidden sticky top-1/2 left-0 container px-[16px] lg:px-[20px] mx-auto transform -translate-y-1/2">
            <div className="flex items-center justify-between max-w-[1212px] lg:gap-[80px] xl:gap-0 mx-auto">
              <div className="lg:flex hidden xl:gap-[120px] lg:gap-[60px] lg:h-[324px] text-[21px] leading-[18px] text-[#3E6BE4]">
                <div className="flex flex-col justify-between w-[220px]">
                  <div className="flex flex-col gap-3">
                    <p
                      className={` transition-colors ease-in-out duration-200 ${getTextColor(
                        1
                      )}`}
                    >
                      {t("sectionTwo.sliderTitle-1")}
                    </p>
                  </div>
                  <div className="flex flex-col gap-3">
                    <p
                      className={` transition-colors ease-in-out duration-200 ${getTextColor(
                        2
                      )}`}
                    >
                      {t("sectionTwo.sliderTitle-2")}
                    </p>
                  </div>

                  <div className="flex flex-col gap-3">
                    <p
                      className={` transition-colors ease-in-out duration-200 ${getTextColor(
                        3
                      )}`}
                    >
                      {t("sectionTwo.sliderTitle-3")}
                    </p>
                  </div>

                  <div className="flex flex-col gap-3">
                    <p
                      className={` transition-colors ease-in-out duration-200 ${getTextColor(
                        4
                      )}`}
                    >
                      {t("sectionTwo.sliderTitle-4")}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col items-center space-y-2">
                  <div
                    className={`lg:w-4 lg:h-4 w-8 h-8 flex items-center justify-center text-base text-white transition-colors ease-in-out duration-200 ${getCircleColor(
                      1
                    )} rounded-full`}
                  >
                    <p className="lg:hidden block">1</p>
                  </div>
                  <div
                    className={`w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                      2
                    )}`}
                  ></div>
                  <div
                    className={`w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                      2
                    )}`}
                  ></div>
                  <div
                    className={`w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                      2
                    )}`}
                  ></div>
                  <div
                    className={`w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                      2
                    )}`}
                  ></div>

                  <div
                    className={`lg:hidden block w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                      2
                    )}`}
                  ></div>
                  <div
                    className={`lg:hidden block w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                      2
                    )}`}
                  ></div>
                  <div
                    className={`lg:hidden block w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                      2
                    )}`}
                  ></div>
                  <div
                    className={`lg:hidden block w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                      2
                    )}`}
                  ></div>

                  <div
                    className={`lg:hidden block w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                      2
                    )}`}
                  ></div>
                  <div
                    className={`lg:w-4 lg:h-4 w-8 h-8 flex items-center justify-center text-base text-white transition-colors ease-in-out duration-200 ${getCircleColor(
                      2
                    )} rounded-full`}
                  >
                    <p className="lg:hidden block">2</p>
                  </div>
                  <div
                    className={`w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                      3
                    )}`}
                  ></div>
                  <div
                    className={`w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                      3
                    )}`}
                  ></div>
                  <div
                    className={`w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                      3
                    )}`}
                  ></div>
                  <div
                    className={`w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                      3
                    )}`}
                  ></div>

                  <div
                    className={`lg:hidden block w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                      3
                    )}`}
                  ></div>
                  <div
                    className={`lg:hidden block w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                      3
                    )}`}
                  ></div>
                  <div
                    className={`lg:hidden block w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                      3
                    )}`}
                  ></div>
                  <div
                    className={`lg:hidden block w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                      3
                    )}`}
                  ></div>
                  <div
                    className={`lg:hidden block w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                      3
                    )}`}
                  ></div>

                  <div
                    className={`lg:w-4 lg:h-4 w-8 h-8 flex items-center justify-center text-base text-white transition-colors ease-in-out duration-200 ${getCircleColor(
                      3
                    )} rounded-full`}
                  >
                    <p className="lg:hidden block">3</p>
                  </div>

                  <div
                    className={`w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                      4
                    )}`}
                  ></div>
                  <div
                    className={`w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                      4
                    )}`}
                  ></div>
                  <div
                    className={`w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                      4
                    )}`}
                  ></div>
                  <div
                    className={`w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                      4
                    )}`}
                  ></div>

                  <div
                    className={`lg:hidden block w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                      4
                    )}`}
                  ></div>
                  <div
                    className={`lg:hidden block w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                      4
                    )}`}
                  ></div>
                  <div
                    className={`lg:hidden block w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                      4
                    )}`}
                  ></div>
                  <div
                    className={`lg:hidden block w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                      4
                    )}`}
                  ></div>
                  <div
                    className={`lg:hidden block w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                      4
                    )}`}
                  ></div>

                  <div
                    className={`lg:w-4 lg:h-4 w-8 h-8 flex items-center justify-center text-base text-white transition-colors ease-in-out duration-200 ${getCircleColor(
                      4
                    )} rounded-full`}
                  >
                    <p className="lg:hidden block">4</p>
                  </div>
                </div>
              </div>
              {/* Tekst sekcija */}
              <AnimatePresence>
                <div className="lg:block hidden">
                  {activeSection === 1 && (
                    <motion.div
                      key="text1"
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      variants={textVariants}
                      className="w-full"
                    >
                      <div className="flex items-center justify-between xl:gap-[121px] lg:gap-[60px] w-full">
                        <img
                          src={stepOne}
                          alt="step-one"
                          style={{
                            boxShadow: "2px 4px 10px 0px rgba(0, 0, 0, 0.30)",
                          }}
                          className="rounded-[25px] border bg-[#467aff] border-[#467aff] "
                        />
                        <div className="max-w-[383px] flex flex-col gap-9">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: t("sectionTwo.sliderText-1"),
                            }}
                            className="text-[18px] leading-[160%] text-black"
                          ></p>
                          <div className="flex flex-col gap-5">
                            <p className="text-[14px] leading-[140%] font-bold">
                              {t("download")}
                            </p>
                            <div className="flex items-center gap-3">
                              <a
                                href="https://apps.apple.com/me/app/tf-trader-investment-ally/id6474622913"
                                target="_blank"
                              >
                                <img
                                  src={iosBtn}
                                  alt="apple-btn"
                                  className="w-[108px] h-[34px]"
                                />
                              </a>
                              <a
                                href="https://play.google.com/store/apps/details?id=com.incoditech.tfcapital&pcampaignid=web_share"
                                target="_blank"
                              >
                                <img
                                  src={googleBtn}
                                  alt="apple-btn"
                                  className="w-[108px] h-[34px]"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  )}
                  {activeSection === 2 && (
                    <motion.div
                      key="text2"
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      variants={textVariants}
                      className="w-full"
                    >
                      <div className="flex items-center justify-between xl:gap-[121px] lg:gap-[60px] w-full">
                        <img
                          src={stepTwo}
                          alt="step-one"
                          style={{
                            boxShadow: "2px 4px 10px 0px rgba(0, 0, 0, 0.30)",
                          }}
                          className="rounded-[25px] border bg-[#467aff] border-[#467aff] "
                        />
                        <div className="max-w-[383px] flex flex-col gap-9">
                          <p className="text-[18px] leading-[160%] text-black">
                            {t("sectionTwo.sliderText-2")}
                          </p>
                          <div className="flex flex-col gap-5">
                            <p className="text-[14px] leading-[140%] font-bold">
                              {t("download")}{" "}
                            </p>
                            <div className="flex items-center gap-3">
                              <a
                                href="https://apps.apple.com/me/app/tf-trader-investment-ally/id6474622913"
                                target="_blank"
                              >
                                <img
                                  src={iosBtn}
                                  alt="apple-btn"
                                  className="w-[108px] h-[34px]"
                                />
                              </a>

                              <a
                                href="https://play.google.com/store/apps/details?id=com.incoditech.tfcapital&pcampaignid=web_share"
                                target="_blank"
                              >
                                <img
                                  src={googleBtn}
                                  alt="apple-btn"
                                  className="w-[108px] h-[34px]"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  )}
                  {activeSection === 3 && (
                    <motion.div
                      key="text3"
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      variants={textVariants}
                      className="w-full"
                    >
                      <div className="flex items-center justify-between xl:gap-[121px] lg:gap-[60px] w-full">
                        <img
                          src={stepThree}
                          alt="step-one"
                          style={{
                            boxShadow: "2px 4px 10px 0px rgba(0, 0, 0, 0.30)",
                          }}
                          className="rounded-[25px] border bg-[#467aff] border-[#467aff] "
                        />
                        <div className="max-w-[383px] flex flex-col gap-9">
                          <p className="text-[18px] leading-[160%] text-black">
                            {t("sectionTwo.sliderText-3")}
                          </p>
                          <div className="flex flex-col gap-5">
                            <p className="text-[14px] leading-[140%] font-bold">
                              {t("download")}{" "}
                            </p>
                            <div className="flex items-center gap-3">
                              <a
                                href="https://apps.apple.com/me/app/tf-trader-investment-ally/id6474622913"
                                target="_blank"
                              >
                                <img
                                  src={iosBtn}
                                  alt="apple-btn"
                                  className="w-[108px] h-[34px]"
                                />
                              </a>
                              <a
                                href="https://play.google.com/store/apps/details?id=com.incoditech.tfcapital&pcampaignid=web_share"
                                target="_blank"
                              >
                                <img
                                  src={googleBtn}
                                  alt="apple-btn"
                                  className="w-[108px] h-[34px]"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  )}
                  {activeSection === 4 && (
                    <motion.div
                      key="text4"
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      variants={textVariants}
                      className="w-full"
                    >
                      <div className="flex items-center justify-between xl:gap-[121px] lg:gap-[60px] w-full">
                        <img
                          src={stepFour}
                          alt="step-one"
                          style={{
                            boxShadow: "2px 4px 10px 0px rgba(0, 0, 0, 0.30)",
                          }}
                          className="rounded-[25px] border bg-[#467aff] border-[#467aff] "
                        />
                        <div className="max-w-[383px] flex flex-col gap-9">
                          <p className="text-[18px] leading-[160%] text-black">
                            {t("sectionTwo.sliderText-4")}
                          </p>
                          <div className="flex flex-col gap-5">
                            <p className="text-[14px] leading-[140%] font-bold">
                              {t("download")}{" "}
                            </p>
                            <div className="flex items-center gap-3">
                              <a
                                href="https://apps.apple.com/me/app/tf-trader-investment-ally/id6474622913"
                                target="_blank"
                              >
                                <img
                                  src={iosBtn}
                                  alt="apple-btn"
                                  className="w-[108px] h-[34px]"
                                />
                              </a>
                              <a
                                href="https://play.google.com/store/apps/details?id=com.incoditech.tfcapital&pcampaignid=web_share"
                                target="_blank"
                              >
                                <img
                                  src={googleBtn}
                                  alt="apple-btn"
                                  className="w-[108px] h-[34px]"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  )}
                </div>
              </AnimatePresence>
            </div>
          </div>
          <div className="lg:hidden block sticky top-1/2 left-0 container px-[16px] lg:px-[20px] mx-auto transform -translate-y-1/2">
            <AnimatePresence>
              <div className="flex flex-col gap-[22px]">
                <div className="flex flex-row items-center gap-4">
                  <svg width="60" height="60" viewBox="0 0 60 60">
                    <circle
                      cx="30"
                      cy="30"
                      r="26"
                      stroke="#CCD0D7"
                      stroke-width="4"
                      fill="transparent"
                    ></circle>

                    <circle
                      cx="30"
                      cy="30"
                      r="26"
                      stroke="#3E6BE4"
                      stroke-width="4"
                      fill="transparent"
                      stroke-dasharray="163.36"
                      stroke-dashoffset="163.36"
                      id="animatedCircle"
                      style={{ transition: "stroke-dashoffset 1s" }}
                    ></circle>
                  </svg>

                  {activeSection === 1 && (
                    <motion.div
                      key="text1"
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      variants={textVariants}
                    >
                      <p className="text-[16px] leading-[18px] text-[#3E6BE4]">
                        {t("sectionTwo.sliderTitle-1")}
                      </p>
                    </motion.div>
                  )}

                  {activeSection === 2 && (
                    <motion.div
                      key="text2"
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      variants={textVariants}
                    >
                      <p className="text-[16px] leading-[18px] text-[#3E6BE4]">
                        {t("sectionTwo.sliderTitle-2")}
                      </p>
                    </motion.div>
                  )}

                  {activeSection === 3 && (
                    <motion.div
                      key="text3"
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      variants={textVariants}
                    >
                      <p className="text-[16px] leading-[18px] text-[#3E6BE4]">
                        {t("sectionTwo.sliderTitle-3")}
                      </p>
                    </motion.div>
                  )}
                  {activeSection === 4 && (
                    <motion.div
                      key="text4"
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      variants={textVariants}
                    >
                      <p className="text-[16px] leading-[18px] text-[#3E6BE4]">
                        {t("sectionTwo.sliderTitle-4")}
                      </p>
                    </motion.div>
                  )}
                </div>
                {activeSection === 1 && (
                  <motion.div
                    key="text1"
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    variants={textVariants}
                    className="w-full flex items-center justify-center flex-col gap-[25px] "
                  >
                    {" "}
                    <img
                      src={stepOne}
                      alt="step-one"
                      style={{
                        boxShadow: "2px 4px 10px 0px rgba(0, 0, 0, 0.30)",
                      }}
                      className="w-[176px] rounded-[25px] border bg-[#467aff] border-[#467aff] "
                    />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t("sectionTwo.sliderText-1"),
                      }}
                      className="text-[12px] leading-[166%] text-black"
                    ></p>
                  </motion.div>
                )}
                {activeSection === 2 && (
                  <motion.div
                    key="text2"
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    variants={textVariants}
                    className="w-full flex items-center justify-center flex-col gap-[25px] "
                  >
                    {" "}
                    <img
                      src={stepTwo}
                      alt="step-two"
                      style={{
                        boxShadow: "2px 4px 10px 0px rgba(0, 0, 0, 0.30)",
                      }}
                      className="w-[176px] rounded-[25px] border bg-[#467aff] border-[#467aff] "
                    />
                    <p className="text-[12px] leading-[166%] text-black">
                      {t("sectionTwo.sliderText-2")}
                    </p>
                  </motion.div>
                )}
                {activeSection === 3 && (
                  <motion.div
                    key="text3"
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    variants={textVariants}
                    className="w-full flex items-center justify-center flex-col gap-[25px] "
                  >
                    {" "}
                    <img
                      src={stepThree}
                      alt="step-three"
                      style={{
                        boxShadow: "2px 4px 10px 0px rgba(0, 0, 0, 0.30)",
                      }}
                      className="w-[176px] rounded-[25px] border bg-[#467aff] border-[#467aff] "
                    />
                    <p className="text-[12px] leading-[166%] text-black">
                      {t("sectionTwo.sliderText-3")}
                    </p>
                  </motion.div>
                )}
                {activeSection === 4 && (
                  <motion.div
                    key="text4"
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    variants={textVariants}
                    className="w-full flex items-center justify-center flex-col gap-[25px] "
                  >
                    {" "}
                    <img
                      src={stepFour}
                      alt="step-four"
                      style={{
                        boxShadow: "2px 4px 10px 0px rgba(0, 0, 0, 0.30)",
                      }}
                      className="w-[176px] rounded-[25px] border bg-[#467aff] border-[#467aff] "
                    />
                    <p className="text-[12px] leading-[166%] text-black">
                      {t("sectionTwo.sliderText-4")}
                    </p>
                  </motion.div>
                )}
              </div>
            </AnimatePresence>
          </div>
        </div>
      </div>

      <div className="lg:mt-[-100px] -mt-[200px]">
        <Scan />
      </div>
      <div className="mx-auto container px-[16px] lg:px-[20px]">
        <QuestionTabs
          data={data}
          title={t("faq.title")}
          subtitle={t("faq.subtitle")}
          subtitleColor="text-[#3E6BE4]"
          showTitleSecond
        />
      </div>
      <Newsletter />
      <div className="py-[62px] container flex flex-col gap-[52px] mx-auto px-[16px] lg:px-[20px]">
        <TitleSection
          title={t("sectionFive.title")}
          subtitle={t("sectionFive.subtitle")}
          subtitleColor="text-[#3E6BE4]"
          showTitleSecond
        />
        <div className="text-[12px] leading-[19px] font-medium text-black lg:px-[96px] px-[16px] flex flex-col gap-5 w-full lg:py-[43px] py-[32px] border border-[#D9D9D9] bg-[#F4F7FF]">
          <p>{t("sectionFive.text-1")}</p>
          <div>
            <p
              dangerouslySetInnerHTML={{
                __html: t("sectionFive.text-2"),
              }}
            ></p>
            <a
              href="https://www.sipc.org/for-investors/investor-faqs#questions-about-brokerage-firms"
              target="_blank"
              className="text-[#3E6BE4] underline underline-offset-2"
              rel={"noreferrer"}
            >
              https://www.sipc.org/for-investors/investor-faqs#questions-about-brokerage-firms
            </a>
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="container mx-auto px-[16px] lg:px-[20px] pt-8">
          <ContentLoader />
        </div>
      ) : (
        news.data &&
        news.data.length > 0 && (
          <NewsReUse
            news={news.data}
            title={t("sectionFive.title-2")}
            navLink="/news#us-stock"
            navLinkTitle={t("sectionFive.button")}
          />
        )
      )}
      <div className="pt-6  lg:pt-10">
        <CapitalProducts />
      </div>
      <div className="container mx-auto px-[16px] lg:px-[20px] lg:py-[70px]">
        <ContactSectionNew />
      </div>
    </>
  );
}

export default Usaberza;
