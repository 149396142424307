import AboutHero from "../components/AboutHero/AboutHero";
import AvailabilitySection from "../components/AvailabilitySection/AvailabilitySection";
import iphones from "../assets/iphonesAbout.png";
import metaTrader from "../assets/forexSlide.png";
import montenegroberza from "../assets/montenegroberza.png";
import appleBtn from "../assets/appleBtn.svg";
import googleBtn from "../assets/googleBtn.svg";
import metatraderLogo from "../assets/metatrader_logo.svg";

import chevronLeftIcon from "../assets/chevron_left.svg";
import chevronRightIcon from "../assets/chevron_right.svg";

import DirectorSection from "../components/Contact/DirectorSection";
import CapitalProducts from "../components/CapitalProducts/CapitalProducts";
import QuestionTabs from "../components/QuestionTabs/QuestionTabs";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import numbersContainerImg from "../assets/number_container_2.png";

function About() {
  const { t } = useTranslation(["whyTf", "faq"]);
  const [currentSlide, setCurrentSlide] = useState(0);

  const [prevSlide, setPrevSlide] = useState(null);
  useEffect(() => {
    setPrevSlide(currentSlide);
  }, [currentSlide]);

  const data = [
    {
      question: t("faq:questionOne.question"),
      answer: t("faq:questionOne.answer"),
    },
    {
      question: t("faq:questionTwo.question"),
      answer: t("faq:questionTwo.answer"),
    },
    {
      question: t("faq:questionNew.question"),
      answer: t("faq:questionNew.answer"),
    },
    {
      question: t("faq:questionThree.question"),
      answer: t("faq:questionThree.answer"),
    },
    {
      question: t("faq:questionFour.question"),
      answer: t("faq:questionFour.answer"),
    },
    {
      question: t("faq:questionFive.question"),
      answer: t("faq:questionFive.answer"),
    },
  ];

  const getSlideClass = (index) => {
    if (index === currentSlide) {
      return "slide-enter";
    } else if (index === prevSlide) {
      return "slide-exit";
    }
    return "hidden";
  };

  // Assuming you have a predefined list of slides (content and themes)
  const slides = [
    {
      content: slide1Content,
      theme: t("whyTf:slider.cardOne.theme"),
    },
    {
      content: slide2Content,
      theme: t("whyTf:slider.cardTwo.theme"),
    },
    {
      content: slide3Content,
      theme: t("whyTf:slider.cardThree.theme"),
    },
  ];

  const handleArrowClick = (direction) => {
    setCurrentSlide((prev) => {
      if (direction === "left") {
        return prev > 0 ? prev - 1 : slides.length - 1;
      } else {
        return prev < slides.length - 1 ? prev + 1 : 0;
      }
    });
  };

  const renderSlide = (index) => {
    const isActive = index === currentSlide;
    const isPrevious = index === prevSlide;
    const slideClass = isActive
      ? "slide-enter"
      : isPrevious
      ? "slide-exit"
      : "hidden";

    return (
      <div key={index} className={`slide-content ${slideClass}`}>
        {slides[index].content()}
      </div>
    );
  };

  function Arrow({ direction, active }) {
    const arrowLeftSvg = (
      <svg
        width="16"
        height="29"
        viewBox="0 0 16 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 0.5L0.999999 14.5L15 28.5"
          stroke={active ? "#FFFFFF" : "#3E6BE4"}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );

    const arrowRightSvg = (
      <svg
        width="16"
        height="29"
        viewBox="0 0 16 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.999998 28.5L15 14.5L0.999999 0.499999"
          stroke={active ? "#FFFFFF" : "#3E6BE4"}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );

    return (
      <div
        onClick={() => active && handleArrowClick(direction)}
        className={` transition-all ease-in-out duration-500 cursor-pointer border-[1px] border-[#3E6BE4] transform -skew-x-[4deg] w-[64px] lg:w-[99px] h-[64px] flex items-center justify-center ${
          active ? "bg-blue-500" : "bg-transparent"
        }`}
      >
        {direction === "left" ? arrowLeftSvg : arrowRightSvg}
      </div>
    );
  }

  function slide1Content() {
    return (
      <>
        <div className="flex flex-col items-center justify-center gap-[32px]">
          <div className="relative lg:max-w-[360px] xl:max-w-[450px] 2xl:max-w-[541px]  h-auto">
            <div className="min-w-[88%] bg-blue-500 min-h-[85%] w-[80%] h-[85%] top-[8%] left-[50%] transform -skew-x-[4deg] translate-x-[-50%] absolute z-20"></div>
            <img
              src={iphones}
              alt="iphones-img"
              className="w-full h-auto relative z-20"
            />
          </div>
          <div className="lg:w-auto flex items-center gap-[18px] lg:justify-start justify-center">
            <a
              href="https://apps.apple.com/us/app/metatrader-5/id413251709?utm_campaign=install.metaquotes&utm_source=www.metatrader5.com"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="lg:w-[155px] w-[128px]"
                src={appleBtn}
                alt="appleBtn"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=net.metaquotes.metatrader5&hl=en&referrer=ref_id%3D5190079549244360941%26server%3DTFCapital-Server&pli=1"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="lg:w-[155px] w-[128px]"
                src={googleBtn}
                alt="googleBtn"
              />
            </a>
          </div>
          <div className="lg:hidden flex flex-col items-center gap-[16px]">
            <div className="flex gap-[16px] items-center">
              <Arrow direction="left" active={currentSlide > 0} />
              <Arrow
                direction="right"
                active={currentSlide < slides.length - 1}
              />
            </div>
            <p className="text-[20px] leading-[24px] font-bold text-[#3E6BE4]">
              {t("whyTf:slider.cardOne.theme")}
            </p>
          </div>
        </div>
      </>
    );
  }
  function slide2Content() {
    return (
      <>
        <div className="flex flex-col items-center justify-center gap-[32px]">
          <div className="relative lg:max-w-[360px] xl:max-w-[450px] 2xl:max-w-[541px]  h-auto">
            <img
              src={metaTrader}
              alt="iphones-img"
              className="w-full h-auto relative z-20 aboutSlideOne"
            />
          </div>
          <div className="lg:hidden flex flex-col items-center gap-[16px]">
            <div className="flex gap-[16px] items-center">
              <Arrow direction="left" active={currentSlide > 0} />
              <Arrow
                direction="right"
                active={currentSlide < slides.length - 1}
              />
            </div>
            <p className="text-[20px] leading-[24px] font-bold text-[#3E6BE4]">
              {t("whyTf:slider.cardTwo.theme")}
            </p>
          </div>
        </div>
      </>
    );
  }
  function slide3Content() {
    return (
      <>
        <div className="flex flex-col items-center justify-center gap-[32px]">
          <div className="relative lg:max-w-[360px] xl:max-w-[450px] 2xl:max-w-[541px]  h-auto">
            <img
              src={montenegroberza}
              alt="iphones-img"
              className="h-auto relative z-20 w-[100%] lg:w-[120%] max-w-[115%]"
            />
          </div>
          <div className="lg:hidden flex flex-col items-center gap-[16px]">
            <div className="flex gap-[16px] items-center">
              <Arrow direction="left" active={currentSlide > 0} />
              <Arrow
                direction="right"
                active={currentSlide < slides.length - 1}
              />
            </div>
            <p className="text-[20px] leading-[24px] font-bold text-[#3E6BE4]">
              {t("whyTf:slider.cardThree.theme")}
            </p>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <AboutHero />
      <AvailabilitySection />
      <div className="w-full bg-[#F4F7FF] 2xl:py-[150px] lg:py-[100px] py-[75px]">
        <div className="mx-auto container px-[16px] lg:px-[20px] flex lg:flex-row flex-col gap-[64px] items-center justify-between">
          <div className="flex flex-col gap-[24px] lg:max-w-[480px] xl:max-w-[590px] 2xl:max-w-[684px] ">
            <label className="text-[#3E6BE4] font-bold 2xl:text-[24px] 2xl:leading-[30px] lg:text-[18px] lg:leading-[24px] text-[16px] leading-[20px]">
              {t("whyTf:slider.cardOne.subtitle")}
            </label>
            <h2 className="text-black font-semibold 2xl:text-[48px] 2xl:leading-[68px] lg:text-[36px] lg:leading-[47px] text-[24px] leading-[32px]">
              {t("whyTf:slider.cardOne.title")}
            </h2>
            <p className="lg:text-[16px] lg:leading-[26px] text-black text-[14px] leading-[22px]">
              {t("whyTf:slider.cardOne.text")}
            </p>
            <div
              className={`lg:flex hidden items-center gap-[32px] lg:pt-[40px] pt-[32px]`}
            >
              <div className="flex gap-[16px] items-center">
                <Arrow direction="left" active={currentSlide > 0} />
                <Arrow
                  direction="right"
                  active={currentSlide < slides.length - 1}
                />
              </div>
              {/* <p className="lg:text-[24px] lg:leading-[29px] font-bold text-[#3E6BE4]">
                {slides[currentSlide].theme}
              </p> */}
              <div className=" w-[215px] min-w-[215px] overflow-hidden">
                <div
                  className={`${
                    currentSlide === 0
                      ? "translate-x-0"
                      : currentSlide === 1
                      ? "translate-x-[-100%]"
                      : "translate-x-[-200%]"
                  }  flex transform transition-transform ease-in-out duration-[.4s]`}
                >
                  <p className="lg:text-[24px] min-w-[215px] lg:leading-[29px] font-bold text-[#3E6BE4]">
                    USA berze
                  </p>
                  <p className="lg:text-[24px] min-w-[215px] lg:leading-[29px] font-bold text-[#3E6BE4]">
                    Forex CFD
                  </p>
                  <p className="lg:text-[24px] min-w-[215px] lg:leading-[29px] font-bold text-[#3E6BE4]">
                    Montenegroberza
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/*  RENDER HERE CONTENT FUNCTIONS*/}
          {/* <div className="slide-container lg:h-[450px] h-[690px]">
            {slides.map((slide, index) => (
              <div
                key={index}
                className={`slide-content ${getSlideClass(index)}`}
              >
                {slide.content()}
              </div>
            ))}
          </div> */}
          <div className="flex-1">
            <div className="flex flex-col items-center justify-center gap-[32px]">
              <div className="relative lg:max-w-[360px] xl:max-w-[450px] 2xl:max-w-[541px] max-w-[450px]  h-auto">
                <div
                  className={`${
                    currentSlide === 0
                      ? "rounded-none to-blue-500 from-blue-500 blur-none"
                      : currentSlide === 1
                      ? "rounded-[40px]  blur-sm  from-blue-800 to-blue-500 scale-100"
                      : "from-blue-800 to-blue-500 scale-[1.10] opacity-0"
                  } min-w-[88%] rounded-[40px]  filter bg-gradient-to-b transition-all min-h-[85%] w-[80%] h-[85%] top-[8%] left-[47%] transform translate-x-[-50%] absolute z-20 ease-in-out duration-500`}
                ></div>
                <div
                  className={`${
                    currentSlide === 0
                      ? "opacity-0"
                      : currentSlide === 1
                      ? "opacity-0"
                      : "opacity-1"
                  } min-w-[88%] montenegro-stock-bg rounded-full shadow-2xl filter blur-2xl transition-all min-h-[85%] w-[80%] h-[85%] top-[8%] left-[47%] transform translate-x-[-50%] absolute z-20 ease-in-out duration-300`}
                ></div>
                <div className="relative z-[99] lg:max-w-[360px] xl:max-w-[450px] 2xl:max-w-[541px] overflow-hidden">
                  <div
                    className={`${
                      currentSlide === 0
                        ? "translate-x-0 "
                        : currentSlide === 1
                        ? "translate-x-[-100%] "
                        : "translate-x-[-200%] delay-300 transition-none"
                    } ${
                      prevSlide === 2 ? "transition-none" : ""
                    } flex items-center gap-[40px] lg:max-w-[360px] xl:max-w-[450px] 2xl:max-w-[541px] transition-all ease-in-out duration-500`}
                  >
                    <img
                      src={iphones}
                      alt="iphones-img"
                      className="w-full h-auto relative z-20"
                    />
                    <img
                      src={metaTrader}
                      alt="iphones-img"
                      className={`${
                        currentSlide === 0
                          ? "opacity-0"
                          : currentSlide === 1
                          ? "opacity-1"
                          : "opacity-0"
                      } duration-500 transition-opacity ease-in-out w-full 2xl:h-[405px] xl:h-[350px] ml-[-60px] 2xl:ml-[-40px] mx-auto relative z-20`}
                    />

                    <img
                      src={numbersContainerImg}
                      alt="numbersContainerImg"
                      className={`${
                        currentSlide === 0
                          ? "opacity-0"
                          : currentSlide === 2
                          ? "opacity-1"
                          : "opacity-0"
                      } transition-opacity duration-300 ease-in-out w-full 2xl:h-[405px] xl:h-[350px] md:ml-0 ml-[20px]  mx-auto relative z-20`}
                    />
                  </div>
                </div>
              </div>
              <div className="w-[330px] mx-auto  overflow-hidden">
                <div
                  className={`${
                    currentSlide === 0
                      ? "translate-x-0"
                      : currentSlide === 1
                      ? "translate-x-[-100%]"
                      : "translate-x-[-200%]"
                  } transition-all ease-in-out duration-500 flex`}
                >
                  <div className="min-w-[330px] lg:w-auto flex items-center gap-[18px] lg:justify-start justify-center">
                    <a
                      href="https://apps.apple.com/us/app/metatrader-5/id413251709?utm_campaign=install.metaquotes&utm_source=www.metatrader5.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="lg:w-[155px] w-[128px]"
                        src={appleBtn}
                        alt="appleBtn"
                      />
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=net.metaquotes.metatrader5&hl=en&referrer=ref_id%3D5190079549244360941%26server%3DTFCapital-Server&pli=1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="lg:w-[155px] w-[128px]"
                        src={googleBtn}
                        alt="googleBtn"
                      />
                    </a>
                  </div>
                  <div className="min-w-[330px] mx-auto">
                    <a
                      href="https://apps.apple.com/us/app/metatrader-5/id413251709?utm_campaign=install.metaquotes&utm_source=www.metatrader5.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={metatraderLogo}
                        alt="iphones-img"
                        className="lg:w-[300px] w-[200px] mx-auto"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div
                className={`flex lg:hidden items-center flex-col justify-center gap-[32px] lg:pt-[40px] `}
              >
                <div className="flex gap-[16px] items-center">
                  <Arrow direction="left" active={currentSlide > 0} />
                  <Arrow
                    direction="right"
                    active={currentSlide < slides.length - 1}
                  />
                </div>
                <div className=" w-[215px] min-w-[215px] overflow-hidden">
                  <div
                    className={`${
                      currentSlide === 0
                        ? "translate-x-0"
                        : currentSlide === 1
                        ? "translate-x-[-100%]"
                        : "translate-x-[-200%]"
                    }  text-center flex transform transition-transform ease-in-out duration-[.4s]`}
                  >
                    <p className="lg:text-[24px] min-w-[215px] lg:leading-[29px] font-bold text-[#3E6BE4]">
                      USA berze
                    </p>
                    <p className="lg:text-[24px] min-w-[215px] lg:leading-[29px] font-bold text-[#3E6BE4]">
                      Forex CFD
                    </p>
                    <p className="lg:text-[24px] min-w-[215px] lg:leading-[29px] font-bold text-[#3E6BE4]">
                      Montenegroberza
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto px-[16px] lg:px-[20px]">
        <DirectorSection />
        <div id="faq">
          <QuestionTabs
            data={data}
            title={t("faq:title")}
            subtitle={t("faq:subtitle")}
          />
        </div>
      </div>
      <div className="2xl:pt-[100px] 2xl:pb-[180px] lg:pt-[75px] lg:pb-[150px] pt-[100px] pb-[160px]">
        <CapitalProducts />
      </div>
    </>
  );
}

export default About;
