import { useTranslation } from "react-i18next";

function AvailabilitySection() {
  const { t } = useTranslation("whyTf");
  return (
    <div className="w-full availability-bg">
      <div className="container mx-auto px-[16px] lg:px-[20px] flex items-center">
        <div className="lg:py-0 py-[32px] flex flex-col gap-[16px] lg:gap-[22px] xl:gap-[32px]">
          <label className="text-[#3E6BE4] font-semibold lg:text-[24px] lg:leading-[32px] text-[16px] leading-[24px]">
            {t("availability.subtitle")}
          </label>
          <h3 className="max-w-[288px] lg:max-w-[575px] lg:pt-[16px] text-black xl:text-[48px] xl:leading-[62px] lg:text-[34px] lg:leading-[46px] font-semibold pt-0 text-[24px] leading-[32px]">
            {t("availability.title")}
          </h3>
          <p className="max-w-[440px] lg:max-w-[581px] 2xl:text-[18px] 2xl:leading-[29px] lg:text-[16px] lg:leading-[24px] text-black lg:pt-0 pt-[16px] text-[14px] leading-[22px]">
            {t("availability.text")}
          </p>
          <button className="mt-[16px] lg:mt-0 max-w-[237px] px-[48px] py-[14px] text-xs leading-[15px] text-white bg-[#293B84] rounded-[3px] font-medium">
            {t("availability.button")}
          </button>
        </div>
        <div className="hidden lg:flex flex-col py-[50px] lg:gap-[50px] xl:gap-[80px] 2xl:pl-[224px] xl:pl-[82px]">
          <div className="pl-[60px] flex flex-col max-w-[500px] lg:gap-[20px] xl:gap-[32px]">
            <h4 className="xl:text-[32px] xl:leading-[38px] lg:text-[26px] lg:leading-[34px] text-[#293b84] font-semibold">
              {t("availability.cardOne.title")}
            </h4>

            <p className="2xl:text-[18px] 2xl:leading-[28px] lg:text-[16px] leading-[24px] text-black">
              {t("availability.cardOne.subtitle")}
            </p>
          </div>
          <div className="h-[1px] w-full bg-[#C8E64C]"></div>
          <div className="pl-[60px]  flex flex-col max-w-[500px] lg:gap-[20px] xl:gap-[32px]">
            <h4 className="xl:text-[32px] xl:leading-[38px] lg:text-[26px] lg:leading-[34px] text-[#293b84] font-semibold">
              {t("availability.cardTwo.title")}
            </h4>

            <p className="2xl:text-[18px] 2xl:leading-[28px] lg:text-[16px] leading-[24px] text-black">
              {t("availability.cardTwo.subtitle")}
            </p>
          </div>
        </div>
      </div>
      <div className="w-full bg-white">
        <div className="bg-white container mx-auto px-[16px]  flex lg:hidden flex-col py-[50px] gap-[50px]">
          <div className="flex flex-col gap-[32px]">
            <h4 className="text-[24px] leading-[32px] text-[#293b84] font-semibold">
              {t("availability.cardOne.title")}
            </h4>

            <p className="text-[14px] leading-[22px] text-black">
              {t("availability.cardOne.subtitle")}
            </p>
          </div>
          <div className="h-[1px] w-full bg-[#C8E64C]"></div>
          <div className="flex flex-col gap-[32px]">
            <h4 className="text-[24px] leading-[32px] text-[#293b84] font-semibold">
              {t("availability.cardTwo.title")}
            </h4>

            <p className="text-[14px] leading-[22px] text-black">
              {t("availability.cardTwo.subtitle")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AvailabilitySection;
