import { useTranslation } from "react-i18next";
import HeroSection from "../components/HeroSection/HeroSection";
import CapitalProducts from "../components/CapitalProducts/CapitalProducts";
import Testemonial from "../components/Testemonial/Testemonial";
import WhyUs from "../components/WhyUs/WhyUs";
import ScanStore from "../components/ScanStore/ScanStore";
import ForexCFD from "../components/ForexCFD/ForexCFD";
import MontenegroStock from "../components/MontenegroStock/MontenegroStock";
import WhoIsTF from "../components/WhoIsTF/WhoIsTF";
import QuestionTabs from "../components/QuestionTabs/QuestionTabs";
import ContactSectionNew from "../components/ContactSectionNew/ContactSectionNew";
import Newsletter from "../components/Newsletter/Newsletter";

function Home() {
  const { t } = useTranslation("faq");

  const data = [
    {
      question: t("questionOne.question"),
      answer: t("questionOne.answer"),
    },
    {
      question: t("questionTwo.question"),
      answer: t("questionTwo.answer"),
    },
    {
      question: t("questionNew.question"),
      answer: t("questionNew.answer"),
    },
    {
      question: t("questionThree.question"),
      answer: t("questionThree.answer"),
    },
    {
      question: t("questionFour.question"),
      answer: t("questionFour.answer"),
    },
    {
      question: t("questionFive.question"),
      answer: t("questionFive.answer"),
    },
  ];

  return (
    <div className="w-full">
      <div className="container mx-auto lg:px-[20px] px-[16px]">
        <HeroSection />
      </div>
      <div id="products" className="lg:pt-[80px] pt-[60px] overflow-x-hidden">
        <CapitalProducts />
      </div>
      <div className="container mx-auto lg:px-[20px] px-[16px]">
        <Testemonial />
      </div>

      <div className="overflow-x-hidden container lg:w-full lg:max-w-full mx-auto lg:px-0 px-[16px] ">
        <WhyUs />
      </div>

      <ScanStore />
      <ForexCFD />
      <MontenegroStock />
      <div className="container mx-auto lg:px-[20px] px-[16px]">
        <WhoIsTF />
      </div>
      <div className="container mx-auto lg:px-[20px] px-[16px] lg:py-[50px] pt-[0] pb-[64px]">
        <QuestionTabs data={data} title={t("title")} subtitle={t("subtitle")} />
        <ContactSectionNew showTitle />
        <Newsletter />
      </div>
    </div>
  );
}

export default Home;
