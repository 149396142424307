import forexLogo from "../../assets/forex_logo.svg";
import eduCardOne from "../../assets/edu_card_one.svg";
import eduCardTwo from "../../assets/edu_card_two.svg";

function EducationHero() {
  return (
    <div className="lg:pt-[100px] lg:pb-[100px] pt-[50px] pb-[40px]">
      <div className="flex overflow-auto lg:overflow-visible relative z-30 pb-[10px] lg:pb-0 lg:gap-[60px] xl:gap-[100px] gap-[20px]">
        <div className=" bg-opacity-20 py-[25px] lg:py-[50px] lg:block flex flex-col items-center justify-between border-[3px] border-[#C8E64C] relative shadow-lg lg:w-[680px] lg:h-[580px] h-[537px] w-full min-w-[336px] transform lg:-skew-x-[4deg] education-card-one-bg">
          <div className="transform lg:skew-x-[4deg] px-[26.5px] lg:pr-0 xl:pl-[80px] lg:pl-[50px]  xl:max-w-[334px] lg:max-w-[310px] font-semibold text-[28px] leading-[28px] text-center lg:text-left lg:text-[42px] lg:leading-[67px] text-black">
            <h3>Vodič kroz platforme TF Capital-a</h3>
          </div>
          <div className="px-[45px] lg:px-0 transform lg:skew-x-[4deg] lg:absolute bottom-auto right-auto lg:bottom-[50px] lg:right-[35px] xl:right-[71px]">
            <img src={eduCardOne} alt="card" />
          </div>
          <div className="transform lg:skew-x-[4deg] lg:absolute bottom-auto left-auto lg:bottom-[50px] lg:left-[35px] xl:left-[71px]">
            <img src={forexLogo} alt="forex-logo" />
          </div>
        </div>
        <div className="py-[25px] lg:py-[50px] lg:block flex flex-col items-center justify-between border-[3px] border-[#D4DEFA] relative shadow-lg lg:w-[680px] lg:h-[580px] h-[537px] w-full min-w-[336px] transform lg:-skew-x-[4deg] education-card-two-bg">
          <div className="transform lg:skew-x-[4deg] px-[26.5px] lg:pr-0 xl:pl-[80px] lg:pl-[50px]  xl:max-w-[334px] lg:max-w-[320px] font-semibold text-[28px] leading-[28px] text-center lg:text-left lg:text-[42px] lg:leading-[67px] text-black">
            <h3>Edukativni sadržaj o investiranju</h3>
          </div>
          <div className="px-[45px] lg:px-0 transform lg:skew-x-[4deg] lg:absolute bottom-auto right-auto lg:bottom-[50px] lg:right-[35px] xl:right-[71px]">
            <img src={eduCardTwo} alt="card" />
          </div>
          <div className="transform lg:skew-x-[4deg] lg:absolute bottom-auto left-auto lg:bottom-[50px] lg:left-[35px] xl:left-[71px]">
            <img src={forexLogo} alt="forex-logo" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EducationHero;
