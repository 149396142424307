import { useTranslation } from "react-i18next";
import tfLogo from "../../assets/tf_logo.png";

function AboutHero() {
  const { t } = useTranslation("whyTf");
  return (
    <div className="mx-auto container px-[16px] lg:px-[20px] 2xl:pt-[194px] 2xl:pb-[150px] lg:pt-[150px] lg:pb-[100px] pt-[50px] pb-[70px] flex flex-col gap-[80px] 2xl:gap-[200px] lg:gap-[166px]">
      <div className="flex justify-between gap-[20px] 2xl:gap-[230px] 2xl:justify-normal">
        <div className="flex flex-col gap-[32px] xl:max-w-[758px] lg:max-w-[500px] max-w-[758px]">
          <h1
            dangerouslySetInnerHTML={{ __html: t("heroSection.title") }}
            className="text-black lg:text-[64px] lg:leading-[68px] text-[48px] leading-[64px] font-semibold"
          />
          <p className="2xl:text-[18px] 2xl:leading-[28px] lg:text-[16px] lg:leading-[24px] text-black text-[16px] leading-[22px] max-w-[678px]">
            {t("heroSection.subtitle")}
          </p>
        </div>
        <img className="lg:block hidden" src={tfLogo} alt="tf-logo" />
      </div>
      <div className="flex flex-col gap-[32px]">
        <h2 className="text-[#3E6BE4] font-semibold text-[24px] leading-[24px]">
          {t("heroSection.regulation")}
        </h2>
        <p className="text-black text-[16px] leading-[24px]">
          {t("heroSection.regulationTextOne")}
        </p>
        <p className="text-black text-[16px] leading-[24px] mt-[-10px]"
           dangerouslySetInnerHTML={{ __html: t("heroSection.regulationTextTwo")}}
        >
        </p>
      </div>
    </div>
  );
}

export default AboutHero;
