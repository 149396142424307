import ForexHero from "../components/ForexHero/ForexHero";
import StocksSLider from "../components/StocksSlider/StocksSlider";
import checkIcon from "../assets/checkIcon.svg";
import checkIconSecondary from "../assets/checkIconSecondary.svg";
import QuestionTabs from "../components/QuestionTabs/QuestionTabs";
import Newsletter from "../components/Newsletter/Newsletter";
import CapitalProducts from "../components/CapitalProducts/CapitalProducts";
import ContactSectionNew from "../components/ContactSectionNew/ContactSectionNew";
import NewsReUse from "../components/NewsReUse/NewsReUse";
import { useState, useEffect, useRef } from "react";
import { motion, useViewportScroll, AnimatePresence } from "framer-motion";
import TitleSection from "../components/TitleSection/TitleSection";
import ForexCards from "../components/ForexCards/ForexCards";
import { useTranslation } from "react-i18next";
import {
  BlogKeys,
  getSpecificNews,
} from "../api/repositories/blogRepository";
import { useQuery } from "@tanstack/react-query";
import ContentLoader from "react-content-loader";

function Forex() {
  const { t } = useTranslation("forexPage");

  const { data: news, isLoading } = useQuery({
    queryKey: [BlogKeys.NEWS + "-forex"],
    queryFn: () => getSpecificNews("Forex", 8),
  });

  const data = [
    {
      question: t("faq.q1.question"),
      answer: t("faq.q1.answer"),
    },
    {
      question: t("faq.q2.question"),
      answer: t("faq.q2.answer"),
    },
    {
      question: t("faq.q3.question"),
      answer: t("faq.q3.answer"),
    },
    {
      question: t("faq.q4.question"),
      answer: t("faq.q4.answer"),
    },
    {
      question: t("faq.q5.question"),
      answer: t("faq.q5.answer"),
    },
  ];

  const textVariants = {
    hidden: {
      opacity: 0,
      y: 30,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    },
    exit: {
      opacity: 0,
      y: -30,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    },
  };
  const [activeSection, setActiveSection] = useState(1);
  const { scrollY } = useViewportScroll();
  const scrollSection = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollSection.current) {
        const rect = scrollSection.current.getBoundingClientRect(); // Dohvatanje dimenzija i pozicije
        const offsetTop = rect.top + window.scrollY;

        const scrollYValue = scrollY.get();

        if (scrollYValue < offsetTop) {
          setActiveSection(1);
        } else if (
          scrollYValue >= offsetTop &&
          scrollYValue < offsetTop + 333
        ) {
          setActiveSection(2);
        } else {
          setActiveSection(3);
        }
      }
    };

    const unsubscribeY = scrollY.onChange(handleScroll);

    return () => {
      unsubscribeY();
    };
  }, [scrollY]);

  const getCircleColor = (circleNumber) =>
    activeSection >= circleNumber ? "bg-[#293B84]" : "bg-[#CCD0D7]";

  const getTextColor = (circleNumber) =>
    activeSection >= circleNumber ? "text-[#293B84]" : "text-[#CCD0D7]";

  return (
    <>
      <ForexHero />
      <div className="forex-stocks-slider pb-2">
        <StocksSLider shouldBackground />
      </div>
      <div
        style={{
          background:
            "linear-gradient(180deg, #D9DBE5 0%, rgba(217, 219, 229, 0.00) 100%)",
        }}
      >
        <div className="lg:pt-20 lg:pb-8 pt-16 pb-[44px] container mx-auto  px-[16px] lg:px-[20px]">
          <div className="flex justify-center items-center lg:flex-row flex-col gap-12 lg:gap-[112px]">
            <div className="max-w-[506px] flex flex-col gap-[64px]">
              <h1
                dangerouslySetInnerHTML={{
                  __html: t("conditions.title-1"),
                }}
                className="text-black text-[30px] leading-[140%] font-medium "
              ></h1>
              <div className="flex flex-col gap-[18px] text-[14px] font-bold leading-normal text-black">
                <div className="flex items-center gap-4">
                  <img src={checkIcon} alt="check-icon" />
                  <p>{t("conditions.option-1")}</p>
                </div>
                <div className="flex items-center gap-4">
                  <img src={checkIcon} alt="check-icon" />
                  <p>{t("conditions.option-2")}</p>
                </div>
                <div className="flex items-center gap-4">
                  <img src={checkIcon} alt="check-icon" />
                  <p>{t("conditions.option-3")}</p>
                </div>
                <div className="flex items-center gap-4">
                  <img src={checkIcon} alt="check-icon" />
                  <p>{t("conditions.option-4")}</p>
                </div>
                <div className="flex items-center gap-4">
                  <img src={checkIcon} alt="check-icon" />
                  <p>{t("conditions.option-5")}</p>
                </div>
              </div>
            </div>
            <div className="lg:w-[2px] lg:h-[380px] h-[2px] w-full bg-white"></div>
            <div className="max-w-[508px] flex flex-col gap-[64px]">
              <h1
                dangerouslySetInnerHTML={{
                  __html: t("conditions.title-2"),
                }}
                className="text-black text-[30px] leading-[140%] font-medium "
              ></h1>
              <div className="flex flex-col gap-[18px] text-[14px] font-bold leading-normal text-black">
                <div className="flex items-center gap-4">
                  <img src={checkIconSecondary} alt="check-icon" />
                  <p>{t("conditions.option-6")}</p>
                </div>
                <div className="flex items-center gap-4">
                  <img src={checkIconSecondary} alt="check-icon" />
                  <p>{t("conditions.option-7")}</p>
                </div>
                <div className="flex items-center gap-4">
                  <img src={checkIconSecondary} alt="check-icon" />
                  <p>{t("conditions.option-8")}</p>
                </div>
                <div className="flex items-center gap-4">
                  <img src={checkIconSecondary} alt="check-icon" />
                  <p>{t("conditions.option-9")}</p>
                </div>
                <div className="flex items-center gap-4">
                  <img src={checkIconSecondary} alt="check-icon" />
                  <p>{t("conditions.option-10")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto px-[16px] lg:px-[20px] lg:pb-[250px] pb-[340px] pt-[64px] lg:pt-[150px]">
        <TitleSection
          title={t("register.title")}
          subtitle={t("register.subtitle")}
        />
      </div>
      <div
        ref={scrollSection}
        className="h-[1333px] relative w-full p-0 m-0 bg-white"
      >
        <div class=" sticky top-1/2 left-0 container px-[16px] lg:px-[20px] mx-auto transform -translate-y-1/2">
          <div className="flex items-center justify-between gap-5 max-w-[1000px] mx-auto">
            <div className="flex lg:gap-12 gap-[16px] lg:h-[221px] h-[500px] text-[21px] leading-[18px] text-[#293b84]">
              <div className="flex flex-col items-center space-y-2">
                <div
                  className={`lg:w-4 lg:h-4 w-8 h-8 flex items-center justify-center text-base text-white transition-colors ease-in-out duration-200 ${getCircleColor(
                    1
                  )} rounded-full`}
                >
                  <p className="lg:hidden block">1</p>
                </div>
                <div
                  className={`w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                    2
                  )}`}
                ></div>
                <div
                  className={`w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                    2
                  )}`}
                ></div>
                <div
                  className={`w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                    2
                  )}`}
                ></div>
                <div
                  className={`w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                    2
                  )}`}
                ></div>

                <div
                  className={`lg:hidden block w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                    2
                  )}`}
                ></div>
                <div
                  className={`lg:hidden block w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                    2
                  )}`}
                ></div>
                <div
                  className={`lg:hidden block w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                    2
                  )}`}
                ></div>
                <div
                  className={`lg:hidden block w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                    2
                  )}`}
                ></div>

                <div
                  className={`lg:hidden block w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                    2
                  )}`}
                ></div>
                <div
                  className={`lg:w-4 lg:h-4 w-8 h-8 flex items-center justify-center text-base text-white transition-colors ease-in-out duration-200 ${getCircleColor(
                    2
                  )} rounded-full`}
                >
                  <p className="lg:hidden block">2</p>
                </div>
                <div
                  className={`w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                    3
                  )}`}
                ></div>
                <div
                  className={`w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                    3
                  )}`}
                ></div>
                <div
                  className={`w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                    3
                  )}`}
                ></div>
                <div
                  className={`w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                    3
                  )}`}
                ></div>

                <div
                  className={`lg:hidden block w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                    3
                  )}`}
                ></div>
                <div
                  className={`lg:hidden block w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                    3
                  )}`}
                ></div>
                <div
                  className={`lg:hidden block w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                    3
                  )}`}
                ></div>
                <div
                  className={`lg:hidden block w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                    3
                  )}`}
                ></div>
                <div
                  className={`lg:hidden block w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                    3
                  )}`}
                ></div>

                <div
                  className={`lg:w-4 lg:h-4 w-8 h-8 flex items-center justify-center text-base text-white transition-colors ease-in-out duration-200 ${getCircleColor(
                    3
                  )} rounded-full`}
                >
                  <p className="lg:hidden block">3</p>
                </div>
              </div>
              <div className="flex flex-col justify-between">
                <div className="flex flex-col gap-3">
                  <p
                    className={` transition-colors ease-in-out duration-200 ${getTextColor(
                      1
                    )}`}
                  >
                    {t("register.stepOneTitle")}
                  </p>
                  <p className="lg:hidden block text-[12px] text-black leading-[19px]">
                    {t("register.stepOneText-1")}
                    <a
                      href="#"
                      className="font-bold underline text-[#293b84] underline-offset-2"
                    >
                      {t("register.stepOneText-2")}
                    </a>{" "}
                    {t("register.stepOneText-3")}
                    <a
                      href="#"
                      className="font-bold underline text-[#293b84] underline-offset-2"
                    >
                      {t("register.stepOneText-4")}
                    </a>
                  </p>
                </div>
                <div className="flex flex-col gap-3">
                  <p
                    className={` transition-colors ease-in-out duration-200 ${getTextColor(
                      2
                    )}`}
                  >
                    {t("register.stepTwoTitle")}
                  </p>
                  <p className="lg:hidden block  text-[12px] text-black leading-[19px]">
                    {t("register.stepTwoText")}
                  </p>
                </div>

                <div className="flex flex-col gap-3">
                  <p
                    className={` transition-colors ease-in-out duration-200 ${getTextColor(
                      3
                    )}`}
                  >
                    {t("register.stepThreeTitle")}
                  </p>
                  <p className="lg:hidden block text-[12px] text-black leading-[19px]">
                    {t("register.stepThreeText")}
                  </p>
                </div>
              </div>
            </div>
            {/* Tekst sekcija */}
            <AnimatePresence>
              <div className="max-w-[609px] lg:block hidden">
                {activeSection === 1 && (
                  <motion.div
                    key="text1"
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    variants={textVariants}
                  >
                    <p className="text-[18px] text-black leading-[29px]">
                      {t("register.stepOneText-1")}
                      <a
                        href="#"
                        className="font-bold underline text-[#293b84] underline-offset-2"
                      >
                        {t("register.stepOneText-2")}
                      </a>{" "}
                      {t("register.stepOneText-3")}
                      <a
                        href="#"
                        className="font-bold underline text-[#293b84] underline-offset-2"
                      >
                        {t("register.stepOneText-4")}
                      </a>
                    </p>
                  </motion.div>
                )}
                {activeSection === 2 && (
                  <motion.div
                    key="text2"
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    variants={textVariants}
                  >
                    <p className="text-[18px] text-black leading-[29px]">
                      {t("register.stepTwoText")}
                    </p>
                  </motion.div>
                )}
                {activeSection === 3 && (
                  <motion.div
                    key="text3"
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    variants={textVariants}
                  >
                    <p className="text-[18px] text-black leading-[29px]">
                      {t("register.stepThreeText")}
                    </p>
                  </motion.div>
                )}
              </div>
            </AnimatePresence>
          </div>
        </div>
      </div>
      <div className="lg:mt-0 mt-[-180px] relative">
        <ForexCards />
      </div>
      <div className="mx-auto container px-[16px] lg:px-[20px]">
        <QuestionTabs
          data={data}
          title={t("faq.title")}
          subtitle={t("faq.subtitle")}
          subtitleColor="text-[#3E6BE4]"
          showTitleSecond
        />
      </div>

      <Newsletter />

      {isLoading ? (
        <div className="container mx-auto px-[16px] lg:px-[20px] pt-8">
          <ContentLoader />
        </div>
      ) : (
        news.data &&
        news.data.length > 0 && (
          <NewsReUse
            news={news.data}
            title={t("news.title")}
            navLink="/news#forex"
            navLinkTitle={t("news.button")}
          />
        )
      )}

      <div className="overflow-hidden lg:pt-6 ">
        <CapitalProducts />
      </div>
      <div className="lg:py-[70px] py-[64px] container mx-auto px-[16px] lg:px-[20px]">
        {" "}
        <ContactSectionNew />
      </div>
    </>
  );
}

export default Forex;
