import { useTranslation } from "react-i18next";
import testemonialImg from "../../assets/testemonial.png";

function Testemonial() {
  const { t } = useTranslation("home");

  return (
    <div className="lg:pb-[40px] lg:pt-[80px] w-full pb-[48px] pt-[104px]">
      <div className="w-full flex items-center h-full relative">
        <div className="lg:relative transform  absolute lg:top-auto lg:left-auto top-[-62px] left-[50%] translate-x-[-50%] lg:translate-x-0">
          <div
            className="lg:block hidden w-0 h-0 absolute top-[75px] right-[-50px] border-b-[50px] border-t-transparent z-[99] transform rotate-[-32deg]
            border-r-[75px] border-r-[#F4F7FF]
            border-l-[75px] border-b-transparent border-l-transparent"
          ></div>{" "}
          <div
            className="lg:h-[251px] lg:w-[230px] lg:rounded-none rounded-full w-[125px] h-[125px] overflow-hidden "
            style={{
              backgroundImage: `url(${testemonialImg})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          ></div>
        </div>
        <div className="bg-[#F4F7FF] flex flex-col gap-[24px] lg:h-[251px] h-auto justify-center lg:pt-[38px] px-[16px] pt-[74px] pb-[20px] lg:pb-0 lg:pr-[92px] lg:pl-[150px] lg:ml-[-62px]">
          <div className="lg:order-1 order-2 flex flex-col">
            <p className="text-[100px] 2xl:text-[100px] lg:text-[84px] leading-[16px] 2xl:leading-[16px] lg:leading-[8px] text-[#788497] font-semibold">
              “
            </p>
            <p className="text-black italic lg:font-semibold text-[12px] leading-[19px]">
              {t("testemonial.description")}
            </p>
          </div>
          <div className="lg:order-2 order-1 flex flex-col gap-[2px] lg:text-left text-center">
            <p className="font-bold text-[10px]  leading-[18px] text-[#788497] ">
            {t("testemonial.director")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testemonial;
