import { useTranslation } from "react-i18next";
import finraLetter from "../../assets/finra_letter.pdf";

import TitleSection from "../TitleSection/TitleSection";

function WhyUs() {
  const { i18n, t } = useTranslation("home");
  const currentLocale = i18n.language;

  return (
    <div className="w-full flex flex-col gap-[60px] pt-[50px] lg:pt-[40px] lg:pb-[120px] pb-[80px] relative z-[999]">
      <TitleSection
        title={t("whyUs.title")}
        subtitle={t("whyUs.subtitle")}
        showTitleSecond
      />
      <div className="flex flex-row lg:gap-[16px] gap-0 items-center justify-center flex-wrap">
        <div className="border-gradient transform lg:-skew-x-[2deg] lg:w-auto w-full">
          <div className="lg:w-[315px] xl:w-[325px] w-full lg:pt-[41px] pt-[32px] pb-[34px] lg:px-[45px] px-[26px] lg:skew-x-[2deg] flex justify-center text-center gap-[36px] flex-col">
            <h4 className="text-black font-medium text-[21px] lg:leading-normal leading-[24px] lg:w-[183px] w-auto mx-auto">
              {t("whyUs.firstCard.title")}
            </h4>
            <div className="flex flex-col gap-[24px]">
              <div className="flex flex-col gap-[8px] lg:text-left text-center">
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("whyUs.firstCard.textOne"),
                  }}
                  className="text-[12px] text-left leading-[19px] text-black"
                />
                <a
                  className="text-[12px] text-left leading-[14.5px] text-[#3e6be4] underline"
                  href={
                    currentLocale === "me"
                      ? "https://www.scmn.me/me/ucesnici-na-trzistu/investiciona-drustva"
                      : "https://www.scmn.me/en/investment-firms"
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("whyUs.firstCard.checkDocument")}
                </a>
              </div>
              <div className="flex flex-col gap-[8px] text-left">
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("whyUs.firstCard.textTwo"),
                  }}
                  className="text-[12px] leading-[19px] text-black"
                />
                <a
                  className="text-[12px] leading-[14.5px] text-[#3e6be4] underline"
                  href={finraLetter}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("whyUs.firstCard.checkDocument")}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="border-gradient transform lg:-skew-x-[2deg] lg:w-auto w-full">
          <div className="lg:w-[315px] xl:w-[325px] w-full pt-[49.5px] pb-[34px] lg:px-[45px] px-[26px] lg:skew-x-[2deg] flex justify-center text-center gap-[36px] flex-col">
            <h4 className="lg:w-[240px] w-full text-black font-medium text-[21px] lg:leading-normal leading-[24px] mx-auto">
              {t("whyUs.secondCard.title")}
            </h4>
            <div className="flex flex-col gap-[8px] lg:text-left text-center" >
              <p
                dangerouslySetInnerHTML={{ __html: t("whyUs.secondCard.text") }}
                className="text-[12px] leading-[19px] text-black lg:text-center text-left"
              />
            </div>
          </div>
        </div>
        <div className="border-gradient transform lg:-skew-x-[2deg] lg:w-auto w-full">
          <div className="lg:w-[315px] xl:w-[325px] w-full pt-[49.5px] pb-[34px] lg:px-[45px] px-[26px] lg:skew-x-[2deg] flex items-center justify-center text-center gap-[36px] flex-col">
            <h4 className="lg:w-[246px] w-full text-black font-medium text-[21px] lg:leading-normal leading-[24px]">
              {t("whyUs.thirdCard.title")}{" "}
            </h4>
            <p className="text-[12px] leading-[19px] text-black lg:text-center text-left">
              {t("whyUs.thirdCard.text")}
            </p>
          </div>
        </div>
        <div className="border-gradient transform lg:-skew-x-[2deg] lg:w-auto w-full">
          <div className="lg:w-[315px] xl:w-[325px] w-full pt-[49.5px] pb-[34px] lg:px-[45px] px-[26px] lg:skew-x-[2deg] flex items-center justify-center text-center lg:gap-[36px] gap-[24px] flex-col">
            <h4 className="text-black font-medium text-[21px] lg:leading-normal leading-[24px]">
              {t("whyUs.fourthCard.title")}
            </h4>
            <div className="flex flex-col gap-[8px] lg:text-left text-center">
              <p
                dangerouslySetInnerHTML={{
                  __html: t("whyUs.fourthCard.textOne"),
                }}
                className="text-[12px] leading-[19px] text-black lg:text-center text-left"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyUs;
