import QuestionTabs from "../components/QuestionTabs/QuestionTabs";
import Newsletter from "../components/Newsletter/Newsletter";
import CapitalProducts from "../components/CapitalProducts/CapitalProducts";
import { useState, useEffect, useRef } from "react";
import { motion, useViewportScroll, AnimatePresence } from "framer-motion";
import TitleSection from "../components/TitleSection/TitleSection";
import MontenegroberzaForm from "../components/MontenegroberzaForm/MontenegroberzaForm";
import { useTranslation } from "react-i18next";
import MontenegroStock from "../components/MontenegroStock/MontenegroStock";

function Montenegroberza() {
  const { t } = useTranslation("montenegroberzePage");

  const data = [
    {
      question: t("faq.q1.question"),
      answer: t("faq.q1.answer"),
    },
    {
      question: t("faq.q2.question"),
      answer: t("faq.q2.answer"),
    },
    {
      question: t("faq.q3.question"),
      answer: t("faq.q3.answer"),
    },
  ];

  const textVariants = {
    hidden: {
      opacity: 0,
      y: 30,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    },
    exit: {
      opacity: 0,
      y: -30,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    },
  };
  const [activeSection, setActiveSection] = useState(1);
  const { scrollY } = useViewportScroll();
  const scrollSection = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollSection.current) {
        const rect = scrollSection.current.getBoundingClientRect();
        const offsetTop = rect.top + window.scrollY;

        const scrollYValue = scrollY.get();

        if (scrollYValue < offsetTop) {
          setActiveSection(1);
        } else if (
          scrollYValue >= offsetTop &&
          scrollYValue < offsetTop + 333
        ) {
          setActiveSection(2);
        } else {
          setActiveSection(3);
        }
      }
    };

    const unsubscribeY = scrollY.onChange(handleScroll);

    return () => {
      unsubscribeY();
    };
  }, [scrollY]);

  const getCircleColor = (circleNumber) =>
    activeSection >= circleNumber ? "bg-[#116663]" : "bg-[#CCD0D7]";

  const getTextColor = (circleNumber) =>
    activeSection >= circleNumber ? "text-[#116663]" : "text-[#CCD0D7]";

  return (
    <>
      <MontenegroStock isMontenegroPage />
      <div className="container mx-auto px-[16px] lg:px-[20px] lg:pb-[250px] pb-[340px] pt-[64px] lg:pt-[150px]">
        <TitleSection
          title={t("stepper.title")}
          subtitle={t("stepper.subtitle")}
          showTitleSecond
        />
      </div>
      <div
        ref={scrollSection}
        className="h-[1333px] relative w-full p-0 m-0 bg-white"
      >
        <div class=" sticky top-1/2 left-0 container px-[16px] lg:px-[20px] mx-auto transform -translate-y-1/2">
          <div className="flex items-center justify-between gap-5 max-w-[1000px] mx-auto">
            <div className="flex lg:gap-12 gap-[16px] lg:h-[221px] h-[500px] text-[21px] leading-[18px] text-[#116663]">
              <div className="flex flex-col items-center space-y-2">
                <div
                  className={`lg:w-4 lg:h-4 w-8 h-8 flex items-center justify-center text-base text-white transition-colors ease-in-out duration-200 ${getCircleColor(
                    1
                  )} rounded-full`}
                >
                  <p className="lg:hidden block">1</p>
                </div>
                <div
                  className={`w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                    2
                  )}`}
                ></div>
                <div
                  className={`w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                    2
                  )}`}
                ></div>
                <div
                  className={`w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                    2
                  )}`}
                ></div>
                <div
                  className={`w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                    2
                  )}`}
                ></div>

                <div
                  className={`lg:hidden block w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                    2
                  )}`}
                ></div>
                <div
                  className={`lg:hidden block w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                    2
                  )}`}
                ></div>
                <div
                  className={`lg:hidden block w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                    2
                  )}`}
                ></div>
                <div
                  className={`lg:hidden block w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                    2
                  )}`}
                ></div>

                <div
                  className={`lg:hidden block w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                    2
                  )}`}
                ></div>
                <div
                  className={`lg:w-4 lg:h-4 w-8 h-8 flex items-center justify-center text-base text-white transition-colors ease-in-out duration-200 ${getCircleColor(
                    2
                  )} rounded-full`}
                >
                  <p className="lg:hidden block">2</p>
                </div>
                <div
                  className={`w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                    3
                  )}`}
                ></div>
                <div
                  className={`w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                    3
                  )}`}
                ></div>
                <div
                  className={`w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                    3
                  )}`}
                ></div>
                <div
                  className={`w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                    3
                  )}`}
                ></div>

                <div
                  className={`lg:hidden block w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                    3
                  )}`}
                ></div>
                <div
                  className={`lg:hidden block w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                    3
                  )}`}
                ></div>
                <div
                  className={`lg:hidden block w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                    3
                  )}`}
                ></div>
                <div
                  className={`lg:hidden block w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                    3
                  )}`}
                ></div>
                <div
                  className={`lg:hidden block w-[2px] h-3 transition-colors ease-in-out duration-200  ${getCircleColor(
                    3
                  )}`}
                ></div>

                <div
                  className={`lg:w-4 lg:h-4 w-8 h-8 flex items-center justify-center text-base text-white transition-colors ease-in-out duration-200 ${getCircleColor(
                    3
                  )} rounded-full`}
                >
                  <p className="lg:hidden block">3</p>
                </div>
              </div>
              <div className="flex flex-col justify-between">
                <div className="flex flex-col gap-3">
                  <p
                    className={` transition-colors ease-in-out duration-200 ${getTextColor(
                      1
                    )}`}
                  >
                    {t("stepper.stepOneTitle")}
                  </p>
                  <p className="lg:hidden block text-[12px] text-black leading-[19px]">
                    {t("stepper.stepOneText")}
                  </p>
                </div>
                <div className="flex flex-col gap-3">
                  <p
                    className={` transition-colors ease-in-out duration-200 ${getTextColor(
                      2
                    )}`}
                  >
                    {t("stepper.stepTwoTitle")}
                  </p>
                  <p className="lg:hidden block  text-[12px] text-black leading-[19px]">
                    {t("stepper.stepTwoText")}
                  </p>
                </div>

                <div className="flex flex-col gap-3">
                  <p
                    className={` transition-colors ease-in-out duration-200 ${getTextColor(
                      3
                    )}`}
                  >
                    {t("stepper.stepThreeTitle")}
                  </p>
                  <p className="lg:hidden block text-[12px] text-black leading-[19px]">
                    {t("stepper.stepThreeText")}
                  </p>
                </div>
              </div>
            </div>
            {/* Tekst sekcija */}
            <AnimatePresence>
              <div className="max-w-[609px] lg:block hidden">
                {activeSection === 1 && (
                  <motion.div
                    key="text1"
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    variants={textVariants}
                  >
                    <p className="text-[18px] text-black leading-[29px]">
                      {t("stepper.stepOneText")}
                    </p>
                  </motion.div>
                )}
                {activeSection === 2 && (
                  <motion.div
                    key="text2"
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    variants={textVariants}
                  >
                    <p className="text-[18px] text-black leading-[29px]">
                      {t("stepper.stepTwoText")}
                    </p>
                  </motion.div>
                )}
                {activeSection === 3 && (
                  <motion.div
                    key="text3"
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    variants={textVariants}
                  >
                    <p className="text-[18px] text-black leading-[29px]">
                      {t("stepper.stepThreeText")}
                    </p>
                  </motion.div>
                )}
              </div>
            </AnimatePresence>
          </div>
        </div>
      </div>
      <div id="montenegro-form" className="lg:mt-0 mt-[-180px] relative">
        <MontenegroberzaForm />
      </div>
      <div className="mx-auto container px-[16px] lg:px-[20px]">
        <QuestionTabs
          data={data}
          title={t("faq.title")}
          subtitle={t("faq.subtitle")}
          showTitleSecond
        />
      </div>

      <Newsletter />
      <div className="pt-8 pb-12  lg:pb-16 overflow-x-hidden">
        <CapitalProducts />
      </div>
    </>
  );
}

export default Montenegroberza;
