import { useTranslation } from "react-i18next";

function PrivacyPolicy() {
  const { t } = useTranslation("privacyPolicyPage");

  return (
    <div className="mx-auto px-[16px] py-[50px] max-w-[36em]">
      <p>{t("privacyPolicy.paragraph1")}</p>
      <p className="pt-4 uppercase">
        <strong>{t("privacyPolicy.title")}</strong>
      </p>
      <p className="pt-4">
        <strong>{t("privacyPolicy.contactDetailsTitle")}</strong>
      </p>
      <p className="pt-2">
        {t("privacyPolicy.contactDetails")}
        <br />
        {t("privacyPolicy.phoneNumber")}
        <br />
        E-mail: <a href="mailto:info@tfcapital.me">info@tfcapital.me</a>
      </p>
      <p className="pt-2">
        <strong>{t("privacyPolicy.title1")}</strong>
      </p>
      <p className="pt-2">
        <strong>{t("privacyPolicy.title2")}</strong>
      </p>
      <p className="pt-2">{t("privacyPolicy.paragraph2")}</p>
      <p className="pt-2">
        {t("privacyPolicy.paragraph3")}
        <a href="https://tfcapital.me/">
          <u>{t("privacyPolicy.linkText")}</u>
        </a>{" "}
        <span dangerouslySetInnerHTML={{ __html: t("privacyPolicy.paragraph4") }}></span>
      </p>
      <p className="pt-4">
        <strong>{t("privacyPolicy.collectionOfPersonalDataTitle")}</strong>
      </p>
      <p className="pt-4" dangerouslySetInnerHTML={{ __html: t('privacyPolicy.link') }}></p>
      <p className="pt-2">{t("privacyPolicy.collectionOfPersonalData")}</p>
      <p className="pt-2">
        <strong>{t("privacyPolicy.informationCollectedTitle")}</strong>
      </p>
      <ul className="pt-2 list-disc">
        <li className="list-disc ml-4 pt-1">
          <p>{t("privacyPolicy.informationCollected1")}</p>
        </li>
        <li className="list-disc ml-4 pt-1">
          <p>{t("privacyPolicy.informationCollected2")}</p>
        </li>
        <li className="list-disc ml-4 pt-1">
          <p>{t("privacyPolicy.informationCollected3")}</p>
        </li>
      </ul>
      <p className="pt-2">{t("privacyPolicy.lawRequirements")}</p>
      <p className="pt-4">
        <strong>{t("privacyPolicy.useAndDisclosureTitle")}</strong>
      </p>
      <p className="pt-2">{t("privacyPolicy.useAndDisclosure")} <a className='text-[#03a5fc]' href="mailto:info@tfcapital.me">info@tfcapital.me</a>.
      </p>
      <p className="pt-2">{t("privacyPolicy.useAndDisclosure2")} </p>
      <p className="pt-2">
        {t("privacyPolicy.useAndDisclosure2")}
      </p>
      <ul className="pt-2">
        <li className="list-disc ml-4 pt-1">
          <p>{t("privacyPolicy.disclosure1")}</p>
        </li>
        <li className="list-disc ml-4 pt-1">
          <p>{t("privacyPolicy.disclosure2")}</p>
        </li>
        <li className="list-disc ml-4 pt-1">
          <p>{t("privacyPolicy.disclosure3")}</p>
        </li>
        <li className="list-disc ml-4 pt-1">
          <p>{t("privacyPolicy.disclosure4")}</p>
        </li>
        <li className="list-disc ml-4 pt-1">
          <p>{t("privacyPolicy.disclosure5")}</p>
        </li>
        <li className="list-disc ml-4 pt-1">
          <p>{t("privacyPolicy.disclosure6")}</p>
        </li>
        <li className="list-disc ml-4 pt-1">
          <p>{t("privacyPolicy.disclosure7")}</p>
        </li>
        <li className="list-disc ml-4 pt-1">
          <p>{t("privacyPolicy.disclosure8")}</p>
        </li>
        <li className="list-disc ml-4 pt-1">
          <p>{t("privacyPolicy.disclosure9")}</p>
        </li>
        <li className="list-disc ml-4 pt-1">
          <p>{t("privacyPolicy.disclosure10")}</p>
        </li>
      </ul>
      <p className="pt-2">{t("privacyPolicy.noSaleOfData")}</p>
      <p className="pt-4">
        <strong>{t("privacyPolicy.dataIntegrityTitle")}</strong>
      </p>
      <p className="pt-2">{t("privacyPolicy.dataIntegrity")}</p>
      <p className="pt-4">
        <strong>{t("privacyPolicy.statisticsAndCookiesTitle")}</strong>
      </p>
      <p className="pt-2">{t("privacyPolicy.statisticsAndCookies")}</p>
      <p className="pt-4">
        <strong>{t("privacyPolicy.rightToInsightTitle")}</strong>
      </p>
      <p className="pt-2">
        {t("privacyPolicy.rightToInsight")}
        <a href="mailto:info@tfcapital.me">info@tfcapital.me</a>.
      </p>
      <p className="pt-4">
        <strong>{t("privacyPolicy.limitedResponsibilityTitle")}</strong>
      </p>
      <p className="pt-2">{t("privacyPolicy.limitedResponsibility")}</p>
      <p className="pt-4">
        <strong>{t("privacyPolicy.clientConsentTitle")}</strong>
      </p>
      <p className="pt-2">{t("privacyPolicy.clientConsent")}</p>
      <p className="pt-4">
        <strong>{t("privacyPolicy.contactUsTitle")}</strong>
      </p>
      <p className="pt-2" dangerouslySetInnerHTML={{ __html: t("privacyPolicy.contactUs") }}>
      </p>
      <p className="pt-4">
        <strong>{t("privacyPolicy.changesAndUpdatesTitle")}</strong>
      </p>
      <p className="pt-2">{t("privacyPolicy.changesAndUpdates")}</p>
    </div>
  );
}

export default PrivacyPolicy;
