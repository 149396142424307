import laptopImg from "../../assets/laptop.png";
import metaTraderIcon from "../../assets/metatrader-icon.svg";
import windowsIcon from "../../assets/windows.svg";
import macOsIcon from "../../assets/macOsIcon.png";
import linuxIcon from "../../assets/linux.svg";
import { useTranslation } from "react-i18next";

function ForexHero() {
  const { t } = useTranslation("forexPage");

  return (
    <div className="forex-hero-cover w-full">
      <div className=" py-12 justify-between w-full items-center gap-9 flex lg:flex-row flex-col mx-auto container px-[16px] lg:px-[20px] ">
        <div className="flex-col justify-start items-start lg:gap-16 gap-12 inline-flex">
          <div className="max-w-[610px] text-black lg:text-[55px] lg:leading-[72px] text-[48px] font-medium leading-[62px]">
            <span>{t("forexHero.title-1")} </span>
            <span className="text-[#293B84] font-bold ">
              {t("forexHero.title-2")}
            </span>
            <span>
              <br />
            </span>
            <span>{t("forexHero.title-3")} </span>
            <span className="text-[#293B84] font-bold">
              {t("forexHero.title-4")}
            </span>
            <span>
              <br />
            </span>
            <span>{t("forexHero.title-5")} </span>
            <span className="text-[#293B84] font-bold">
              {t("forexHero.title-6")}
            </span>
          </div>
          <div className="text-black text-sm leading-5 lg:text-base lg:leading-normal max-w-[527px]">
            <p
              dangerouslySetInnerHTML={{
                __html: t("forexHero.text"),
              }}
            ></p>
          </div>
          <div className="lg:justify-start justify-center items-center gap-5 inline-flex flex-wrap">
            <a
              href="https://www.metatrader5.com/en"
              target="_blank"
              rel="noreferrer"
            >
              <img src={metaTraderIcon} alt="" />
            </a>
            <a
              href="https://download.mql5.com/cdn/web/metaquotes.software.corp/mt5/mt5setup.exe?utm_source=www.metatrader5.com&amp;utm_campaign=download"
              className="flex items-center gap-[12px]"
            >
              <img src={windowsIcon} alt="Windows Icon" />
              <span className="text-[15px] leading-[36px] text-[#788497]">
                Windows App
              </span>
            </a>
            <a
              className="flex items-center gap-[12px]"
              href="https://download.mql5.com/cdn/web/metaquotes.software.corp/mt5/MetaTrader5.pkg.zip?utm_source=www.metatrader5.com&utm_campaign=download.mt5.macos"
            >
              <img src={macOsIcon} alt="Mac Os Icon" className="w-[20px]" />
              <span className="text-[15px] leading-[36px] text-[#788497]">
                MacOS App
              </span>
            </a>
            <a
              className="flex items-center gap-[12px]"
              href="https://www.mql5.com/en/articles/625?utm_source=www.metatrader5.com&utm_campaign=download.mt5.linux"
              target="_blank"
              rel="noreferrer"
            >
              <img src={linuxIcon} alt="Linux Icon" />
              <span className="text-[15px] leading-[36px] text-[#788497]">
                Linux App
              </span>
            </a>
          </div>
        </div>
        <img
          src={laptopImg}
          alt="bg"
          className=" w-auto lg:w-[440px] xl:w-auto"
        />
      </div>
    </div>
  );
}

export default ForexHero;
