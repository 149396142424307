import locationSvg from "../../assets/location-gray.svg";
import mailSvg from "../../assets/mail-gray.svg";

function MapLocation() {
  return (
    <div className="flex flex-col gap-[36px] lg:w-[610px] w-full ">
      <iframe
        title="tfcapital-location"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11777.447238132529!2d19.23854978038768!3d42.44131735555308!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x134deb25624131ef%3A0x9b89963aaa0bae2f!2s4%20Rimski%20Trg%2C%20Podgorica%2C%20Montenegro!5e0!3m2!1sen!2s!4v1700314789826!5m2!1sen!2s"
        className="w-full h-[310px]"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
      <div className="flex flex-col gap-[22px] lg:text-[14px] text-[12px] lg:leading-[22px] leading-[19px] font-bold text-[#3E6BE4]">
        <div className="flex gap-4">
          <img src={locationSvg} alt="Location Icon" />
          <p>Bulevar Džordža Vašingtona br. 83, Podgorica.</p>
        </div>
        <div className="flex gap-4">
          <img src={mailSvg} alt="Mail Icon" />{" "}
          <a href="mailto:info@tfcapital.me">info@tfcapital.me</a>
        </div>
      </div>
    </div>
  );
}

export default MapLocation;
